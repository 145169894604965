/* eslint-disable array-callback-return */
import React, { useState, useEffect, useCallback, useRef } from 'react'
import { Stage, Layer, Circle, Line, Group, Image, Text } from 'react-konva'
import { type SystemInfoDto, SystemApi, type PrjSystem, type SpanResult, type CalculationResultForUserPosture, type SmartComponentDesignationDto, ProductApi, type ProjectDto } from '../../../common/services'
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined'
import TouchAppOutlinedIcon from '@mui/icons-material/TouchAppOutlined'
import ControlCameraOutlinedIcon from '@mui/icons-material/ControlCameraOutlined'
import RotateLeftOutlinedIcon from '@mui/icons-material/RotateLeftOutlined'
import RotateRightOutlinedIcon from '@mui/icons-material/RotateRightOutlined'
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import LockOpenIcon from '@mui/icons-material/LockOpen'
import LockIcon from '@mui/icons-material/Lock'
import ZoomInIcon from '@mui/icons-material/ZoomIn'
import ZoomOutIcon from '@mui/icons-material/ZoomOut'
import ClearIcon from '@mui/icons-material/Clear'
import CloseSharpIcon from '@mui/icons-material/CloseSharp'
import { Accordion, AccordionDetails, AccordionSummary, Alert, Box, Button, FormControl, FormControlLabel, InputAdornment, Grid, IconButton, MenuItem, Radio, RadioGroup, Select, TextField, Typography, OutlinedInput, InputLabel, styled, Tooltip } from '@mui/material'
import { systemLayoutClass } from '../Helpers/Systemlayout'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { ContainerModelReport, EqualSpan, OutputDiagram } from '.'
import { useTranslation } from 'react-i18next'
import { type SpanInfo, type PeakTensionSpan, type CirclePoint, type CustomSpanDto, type SrlLengthAtFall, type EqualSpans, type SpanData, type ImagePosition, type ContainerPosition, type ListAction, type CurrentLatLng } from '../Helpers/CanvasProps'
import { useQuery } from '@tanstack/react-query'
import ModalComponent from './modalComponent'
import cloneDeep from 'lodash/cloneDeep'
import { Autocomplete } from '@react-google-maps/api'
import type Konva from 'konva'
import KeyTranslation from '../../../common/KeyTranslation.json'
import { ToastContainer, toast } from 'react-toastify'
interface CanvasProps {
  systemData: SystemInfoDto
  projectData: ProjectDto
  setIsLoading: (isLoading: boolean) => void
}
const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1
})

export default function Canvas ({ systemData, projectData, setIsLoading }: CanvasProps): JSX.Element {
  const { data: product, refetch: productRefetch } = useQuery(
    ['/api/v2/Product/GetComponents', projectData?.regionId, projectData?.standardId, projectData?.unitId, systemData.systemTypeId, systemData.applicationLocationId],
    async () => {
      const filterProductInfo: any = {
        countryId: projectData?.regionId,
        standardId: projectData?.standardId,
        unitId: projectData?.unitId,
        systemTypeId: systemData.systemTypeId,
        applicationLocationId: systemData.applicationLocationId
      }
      const { data } = await new ProductApi().apiV2ProductGetComponentsGet(filterProductInfo)
      return data
    },
    { enabled: false }
  )
  const [anchorEl, setAnchorEl] = useState<SpanData>({
    visible: false,
    x: undefined,
    y: undefined,
    span: undefined,
    index: undefined,
    spanIndex: undefined,
    angle: undefined,
    isFirstPoint: false,
    isLastPoint: false,
    isConner: false
  })
  const { t } = useTranslation()
  const drawColor = 'red'
  const componentColor = '#F5A623'
  const highLightColor = '#7ED321'
  const stageRef = useRef<Konva.Stage | null>(null)
  const [saveSysFlag, setSaveSysFlag] = useState(false)
  const [implementClicked, setImplementClicked] = useState(false)
  const [message, setMessage] = useState({
    type: '',
    message: ''
  })
  const [calMessage, setCalMessage] = useState({
    standing: ''
  })
  const [warning, setWarning] = useState({
    standing: ''
  })
  const [peakTensionSpan, setPeakTensionSpan] = useState<PeakTensionSpan>({
    standing: undefined
  })
  const [srlLengthAtFall, setSrlLengthAtFall] = useState<SrlLengthAtFall>({
    standing: undefined
  })
  const [pointSelected, setPointSelected] = useState(-1)
  const [systemCalculateResult, setSystemCalculateResult] = useState<CalculationResultForUserPosture | undefined>()
  const [spanResultIndex, setSpanResultIndex] = useState<number | undefined>(0)
  const oneGridWidth = 10
  const distanceFromGround: string = systemData.locationMetrics != null ? JSON.parse(systemData.locationMetrics)[0] : ''
  const locationMetrics: string = systemData.locationMetrics != null ? JSON.parse(systemData.locationMetrics)[1] : ''
  const raise: string = systemData.locationMetrics != null ? JSON.parse(systemData.locationMetrics)[2] : ''
  const [gridSquareWidth, setGridSquareWidth] = useState<number>(systemData.realSquare ?? 10)
  const [massValue, setMassValue] = useState({
    massValue1: 100,
    massValue2: 140
  })
  useEffect(() => {
    if (projectData?.unitId != null) {
      setMassValue(
        projectData.unitId === 2
          ? {
              massValue1: 220,
              massValue2: 310
            }
          : {
              massValue1: 100,
              massValue2: 140
            }
      )
    }
  }, [projectData?.unitId])
  const [visibleReport, setVisibleReport] = React.useState(false)
  const [users, setUsers] = useState(systemData.numberOfUsers !== undefined ? systemData.numberOfUsers : 1)
  const realTotalCanvasWidth = oneGridWidth * 30
  const [drawMode, setDrawMode] = useState(true) // trạng thái vẽ
  const [select, setSelect] = useState(false)
  const [dragging, setDragging] = useState(false) // trạng thái kéo thả
  const [containerPosition, setContainerPosition] = useState<ContainerPosition>({ x: 0, y: 0 }) // vị trí hình vẽ
  const [canvasWidth, setCanvasWidth] = useState(0)
  const [startPoint, setStartPoint] = useState({ x: 0, y: 0 }) // vị trí của circles đầu tiên
  const [newStartPoint, setNewStartPoint] = useState({ x: 0, y: 0 }) // vị trí của circles sau khi được kéo
  // const [positionXY, setpositionXY] = useState({ x: 0, y: 0 })
  const [listCircles, setListCircles] = useState<CirclePoint[]>([])
  const [clicked, setClicked] = useState(false)
  const [clickedIndex, setClickedIndex] = useState<number | undefined>(undefined)
  const [selectedFormControlValue, setSelectedFormControlValue] = useState<string>('Variable') // check radio equal, variable, Max-Span
  const [selectedUserMass, setSelectedUserMass] = useState<number>(systemData.userWeight !== undefined && systemData.userWeight !== 0 ? systemData.userWeight : massValue.massValue1) // check user mass
  const [currentUserPosture, setCurrentUserPosture] = useState<string>('standing')
  const [openAccordionIndex, setOpenAccordionIndex] = useState(-1)
  const [mapGroundRatio, setmapGroundRatio] = useState(1)
  const [mousePosition, setMousePosition] = useState({
    x: 0,
    y: 0
  })
  let endPoint = { x: 0, y: 0 } // check endpoint modalcpn

  const [equalSpan, setEqualSpan] = useState<EqualSpans>({
    visible: false,
    data: undefined
  })
  const [outputModal, setOutputModal] = useState(false)
  let indexSpan = 0

  let adjustRatioWidthRef = 1
  const numRows = 30
  const numCols = 30
  const cellSize = canvasWidth / numRows
  const scaleFactor = oneGridWidth / gridSquareWidth
  const listSpan = systemLayoutClass.getAllSpansFromCircles(listCircles)
  // State Image
  const [showIcons, setShowIcons] = useState<boolean>(false)
  const [uploadedImage, setUploadedImage] = useState<HTMLImageElement | null>(null)
  const [imageWh, setImageWh] = useState({
    width: 0,
    height: 0
  })
  const [imagePosition, setImagePosition] = useState<ImagePosition>({
    x: 0,
    y: 0
  })
  const [bgOriginalSize, setBgOriginalSize] = useState({
    width: 0,
    height: 0
  })
  const imageRef = useRef(null)
  const [, setUserLock] = useState('')
  const [zoomScale, setZoomScale] = useState(1)
  const [rotationAngle, setRotationAngle] = useState<number>(0)
  const [activeIcon, setActiveIcon] = useState<string | null>(null)
  const [draggable, setDraggable] = useState(false)
  const [actionHistory, setActionHistory] = useState<ListAction[]>([]) // Mảng lưu lịch sử các hành động
  const [actionHistoryIndex, setActionHistoryIndex] = useState<number>(0) // Chỉ số lịch sử hiện tại
  // state googlemap
  const [showMap, setShowMap] = useState(false)
  const [map, setMap] = useState<google.maps.Map | null>(null)
  const [autocomplete, setAutocomplete] = useState<google.maps.places.Autocomplete | null>(null)
  const markers = []
  const [currentLatLng, setCurrentLatLng] = useState<CurrentLatLng>({
    lat: 51.50612143474575,
    lng: -0.12020587921142578,
    scale: 6
  }
  )
  // handleGoogleMap
  useEffect(() => {
    if (showMap) {
      const mapOptions = {
        center: { lat: currentLatLng.lat, lng: currentLatLng.lng },
        zoom: currentLatLng.scale,
        mapTypeId: google.maps.MapTypeId.HYBRID
      }
      const mapElement = document.getElementById('google-map')
      if (mapElement != null) {
        const googleMap = new window.google.maps.Map(mapElement, mapOptions)
        setMap(googleMap)
      }
    }
  }, [showMap])
  const handlePlaceSelect = (): void => {
    if ((autocomplete != null) && (map != null)) {
      const place = autocomplete.getPlace()
      if (place.geometry == null) {
        alert(t(KeyTranslation.System_AutocompleteReturnedPlaceContainsNoGeometry))
        return
      }
      if (place.geometry?.location != null) {
        map.setCenter(place.geometry.location)
        map.setZoom(20)
        const marker = new window.google.maps.Marker({
          map,
          position: new google.maps.LatLng(place.geometry.location.lat(), place.geometry.location.lng())
        })
        markers.push(marker)
      }
    }
  }
  const handleSelectMap = (): void => {
    const apiKey = 'AIzaSyD8uk0Lf0CzR7lSvFLQogQdG4yPfZ4eGAg'
    if (map != null) {
      const a = {
        lat: map.getCenter()?.lat() ?? 0,
        lng: map.getCenter()?.lng() ?? 0,
        scale: map.getZoom() ?? 0
      }
      setCurrentLatLng(a)
      const width = Math.ceil(canvasWidth)
      const size = `${width}x${width}`
      const mapImageUrl = `https://maps.googleapis.com/maps/api/staticmap?center=${a.lat},${a.lng}&zoom=${a.scale - 1}&format=jpg&maptype=satellite&&size=${size}&key=${apiKey}`
      const xhr = new XMLHttpRequest()
      xhr.responseType = 'blob'
      xhr.onload = function () {
        const blob = xhr.response
        const reader = new FileReader()
        reader.onloadend = function () {
          const base64data = reader.result
          if (typeof base64data === 'string') {
            const image = new window.Image()
            image.onload = () => {
              setUploadedImage(image)
              rotatePdfImageBackground(image.width, image.height, image)
            }
            image.src = base64data
          }
        }
        reader.readAsDataURL(blob)
      }
      xhr.open('GET', mapImageUrl)
      xhr.send()
    }
  }
  // handleImage
  const handleRotationChange = (newRotation: number): void => {
    if (imageRef.current != null) {
      const node: Konva.Image = imageRef.current
      systemLayoutClass.rotateAroundCenter(node, newRotation)
      setRotationAngle(newRotation)
    }
  }

  const handleUndoAction = (): void => {
    setSaveSysFlag(false)
    if (actionHistoryIndex > 0) {
      setActionHistoryIndex(actionHistoryIndex - 1)
      const prevAction = actionHistory[actionHistoryIndex - 1]
      setListCircles([...prevAction.listCircles])
      setContainerPosition({ ...prevAction.containerPosition })
      setUploadedImage(prevAction.uploadedImage)
      setImagePosition(prevAction.imagePosition)
      setImageWh(prevAction.imageWh)
      setZoomScale(prevAction.zoomScale)
    }
    if (actionHistoryIndex === 0) {
      setListCircles([])
      setStartPoint({ x: 0, y: 0 })
      setActionHistoryIndex(-1)
      setUploadedImage(null)
    }
  }
  const handleRedoAction = (): void => {
    setSaveSysFlag(false)
    if (actionHistoryIndex < actionHistory.length - 1) {
      setActionHistoryIndex(actionHistoryIndex + 1)
      const nextAction = actionHistory[actionHistoryIndex + 1]
      setListCircles([...nextAction.listCircles])
      setContainerPosition({ ...nextAction.containerPosition })
      setUploadedImage(nextAction.uploadedImage)
      setImagePosition(nextAction.imagePosition)
      setImageWh(nextAction.imageWh)
      setZoomScale(nextAction.zoomScale)
    }
  }
  const handleLock = (): void => {
    if (uploadedImage != null) {
      setShowIcons(true)
    } else {
      alert(t(KeyTranslation.System_ImageBackground_invalid))
    }
  }
  const handleFileChange = (e: any): void => {
    const file = e.target.files?.[0]
    if (file === undefined) {
      return
    }
    if (file.size > 2000000) {
      alert('FileSizeTooBig')
      return
    }
    const reader = new FileReader()
    reader.onload = (event) => {
      if (event.target != null) {
        const dataURL = event.target.result
        if (typeof dataURL === 'string') {
          const image = new window.Image()
          image.onload = () => {
            setUploadedImage(image)
            rotatePdfImageBackground(image.width, image.height, image)
            setZoomScale(1)
          }
          image.src = dataURL
        }
      }
    }
    reader.readAsDataURL(file)

    e.target.value = null
  }

  const rotatePdfImageBackground = (imageW: number, imageH: number, image: HTMLImageElement): void => {
    const width = imageW > imageH ? canvasWidth : (imageW * canvasWidth / imageH)
    const height = imageW > imageH ? (imageH * canvasWidth / imageW) : canvasWidth
    const x = (canvasWidth - width) / 2
    const y = (canvasWidth - height) / 2
    const newActionHistoryItem = {
      listCircles: [...listCircles],
      containerPosition: { ...containerPosition },
      zoomScale: 1,
      imagePosition: { x, y },
      uploadedImage: image,
      rotationAngle,
      imageWh: { width, height }
    }
    const newActionHistory = [...actionHistory.slice(0, actionHistoryIndex + 1), newActionHistoryItem]
    setActionHistory(newActionHistory)
    setActionHistoryIndex(newActionHistory.length - 1)
    setImageWh({ width, height })
    setBgOriginalSize({ width, height })
    setImagePosition({ x, y })
    setShowMap(false)
    setZoomScale(1)
  }

  const handleMoveImage = (e: any): void => {
    const newImagePosition = {
      x: e.target.x(),
      y: e.target.y()
    }
    setImagePosition(newImagePosition)
    const newActionHistory = actionHistory.slice(0, actionHistoryIndex + 1)
    newActionHistory.push({
      listCircles: [...listCircles],
      containerPosition: { ...containerPosition },
      zoomScale,
      imagePosition: newImagePosition,
      uploadedImage,
      rotationAngle,
      imageWh: { ...imageWh }
    })
    setActionHistory(newActionHistory)
    setActionHistoryIndex(newActionHistory.length - 1)
  }
  const handleZoom = (zoomDirection: 'in' | 'out'): void => {
    const zoomStep = zoomDirection === 'in' ? 0.02 : -0.02
    const newZoomScale = parseFloat(zoomScale.toString()) + zoomStep
    setZoomScale(newZoomScale)

    const newX = (imagePosition.x ?? 0) + (imageWh.width - bgOriginalSize.width * newZoomScale) / 2
    const newY = (imagePosition.y ?? 0) + (imageWh.height - bgOriginalSize.height * newZoomScale) / 2
    setImagePosition({ x: newX, y: newY })

    setImageWh({
      width: bgOriginalSize.width * newZoomScale,
      height: bgOriginalSize.height * newZoomScale
    })
    const newActionHistory = actionHistory.slice(0, actionHistoryIndex + 1)
    newActionHistory.push({
      listCircles: [...listCircles],
      containerPosition: { ...containerPosition },
      zoomScale: newZoomScale,
      imagePosition: ({ x: newX, y: newY }),
      uploadedImage,
      rotationAngle,
      imageWh: ({
        width: bgOriginalSize.width * newZoomScale,
        height: bgOriginalSize.height * newZoomScale
      })
    })
    setActionHistory(newActionHistory)
    setActionHistoryIndex(newActionHistory.length - 1)
  }
  const handleUnlock = (): void => {
    setShowIcons(false)
  }
  const handleDeleteImage = (): void => {
    if (uploadedImage != null) {
      setUploadedImage(null)
      setBgOriginalSize({ width: 0, height: 0 })
      setImageWh({ width: 0, height: 0 })
      setImagePosition({ x: 0, y: 0 })
      setCurrentLatLng({
        lat: 51.50612143474575,
        lng: -0.12020587921142578,
        scale: 6
      })
      const newActionHistory = actionHistory.slice(0, actionHistoryIndex + 1)
      newActionHistory.push({
        listCircles: [...listCircles],
        containerPosition: { ...containerPosition },
        zoomScale: 1,
        imagePosition: ({ x: 0, y: 0 }),
        uploadedImage: null,
        rotationAngle,
        imageWh: ({ width: 0, height: 0 })
      })
      setZoomScale(1)
      setActionHistory(newActionHistory)
      setActionHistoryIndex(newActionHistory.length - 1)
    }
  }
  const handleMoveImageClick = (): void => {
    if (!draggable) {
      setDraggable(true)
      setDrawMode(false)
      setSelect(false)
      setDragging(false)
    } else {
      setDraggable(false)
    }
  }
  // handleCanvas
  const handleLineClick = (index: number): void => {
    setClicked(true)
    setClickedIndex(index)
  }
  const updateStageWidth = useCallback(() => {
    const boxElement = document.querySelector('.system-bottom')
    if (boxElement != null) {
      const newStageWidth = boxElement.clientWidth - 62
      setCanvasWidth(newStageWidth)
      const mapGroundRatio = systemLayoutClass.getMapGroundRatio(newStageWidth, projectData?.unitId, realTotalCanvasWidth)
      setmapGroundRatio(mapGroundRatio)
    }
  }, [])

  const handleCanvasClick = (e: { evt: { offsetX: number, offsetY: number } }): void => {
    const newCircle = {
      x: (e.evt.offsetX - (containerPosition.x ?? 0) - startPoint.x) / scaleFactor + startPoint.x,
      y: (e.evt.offsetY - (containerPosition.y ?? 0) - startPoint.y) / scaleFactor + startPoint.y
    }
    if (drawMode) {
      if (listCircles === undefined || listCircles.length === 0) {
        setStartPoint(newCircle)
        setNewStartPoint(newCircle)
        setListCircles([newCircle])
        const newActionHistory = actionHistory.slice(0, 1)
        newActionHistory.push({
          listCircles: [...[newCircle]],
          containerPosition: { ...containerPosition },
          zoomScale,
          imagePosition: { ...imagePosition },
          uploadedImage,
          rotationAngle,
          imageWh: { ...imageWh }
        })
        setActionHistory(newActionHistory)
        setActionHistoryIndex(newActionHistory.length - 1)
      } else {
        const lastCircle = listCircles[listCircles.length - 1]
        const { angle, length } = systemLayoutClass.calculateAngleAndLength(newCircle, lastCircle)
        addLeg(length, angle, lastCircle, newCircle)
      }
      setSaveSysFlag(false)
    }
  }
  const drawSelectedCircle = (x: number, y: number, span: CustomSpanDto, index: number, spanIndex: number, isFirstPoint: boolean, isLastPoint: boolean, isConner: boolean, angle?: number): void => {
    setSaveSysFlag(false)
    if ((index + spanIndex) === 1 && (listCircles?.[1].leg?.spans?.[0].components == null || listCircles?.[1].leg?.spans?.[0].components.length <= 0)) {
      alert(t(KeyTranslation.PleaseChooseComponentForStartPointFirst))
    } else {
      setAnchorEl({
        visible: true,
        x,
        y,
        span,
        index,
        spanIndex,
        angle,
        isFirstPoint,
        isLastPoint,
        isConner
      })
    }
    if (index <= 1) {
      if (listCircles?.[1].leg?.spans?.[0].components != null && listCircles?.[1].leg?.spans?.[0].components.length >= index) {
        if (index === 0) {
          setPointSelected(0)
        } else {
          setPointSelected(1)
        }
      }
    } else {
      setPointSelected(-1)
    }
  }

  const addLeg = (length: number, angle: number, lastCircle?: CirclePoint, newCircle?: any): any => {
    let circleX: number = 0
    let circleY: number = 0
    if (length === 0 && lastCircle !== undefined) {
      length = systemLayoutClass.ConvertFromRealMeterToCanvasPixels(15, projectData?.unitId, mapGroundRatio)
      const { x, y } = systemLayoutClass.lineToAngle(lastCircle?.x, lastCircle?.y, length, angle)
      circleX = x
      circleY = y
    }
    let displayAngle = -1 * (angle)
    const realLength = systemLayoutClass.ConvertFromCanvasPixelsToRealMeter(Number(length.toFixed(2)), projectData?.unitId, mapGroundRatio)
    if (listCircles !== undefined && listCircles.length > 1) {
      displayAngle = systemLayoutClass.getLegsRelativeAngle(lastCircle?.leg?.realAngle ?? 0, angle)
    }
    let x, y
    if (listCircles !== undefined && listCircles.length <= 0) {
      const { coordinateX, coordinateY } = systemLayoutClass.getCoordinateXY(0, 0, realLength, displayAngle)
      x = coordinateX
      y = coordinateY
    } else {
      const { coordinateX, coordinateY } = systemLayoutClass.getCoordinateXY(lastCircle?.leg?.coordinateX ?? 0, lastCircle?.leg?.coordinateY ?? 0, realLength, -1 * angle)
      x = coordinateX
      y = coordinateY
    }
    const newObj = {
      name: `Leg ${listCircles?.length ?? 1 ?? 0}`,
      angle: parseFloat(displayAngle.toFixed(2)),
      realAngle: parseFloat(angle.toFixed(2)),
      clearanceAmount: 0,
      length: parseFloat(realLength.toFixed(2)),
      spans: [
        {
          name: 'Sp',
          length: parseFloat(realLength.toFixed(2)),
          components: [],
          x: newCircle !== undefined ? newCircle.x : circleX,
          y: newCircle !== undefined ? newCircle.y : circleY
        }],
      spanType: 'variable',
      coordinateX: x,
      coordinateY: y
    }
    const newActionHistory = actionHistory.slice(0, actionHistoryIndex + 1)
    newActionHistory.push({
      listCircles: [...listCircles ?? [], { x: newCircle !== undefined ? newCircle.x : circleX, y: newCircle !== undefined ? newCircle.y : circleY, leg: newObj }],
      containerPosition: { ...containerPosition },
      zoomScale,
      imagePosition: { ...imagePosition },
      uploadedImage,
      rotationAngle,
      imageWh: { ...imageWh }
    })
    setActionHistory(newActionHistory)
    setActionHistoryIndex(newActionHistory.length - 1)
    setListCircles((prevListCircles) => [...(prevListCircles ?? []), { x: newCircle !== undefined ? newCircle.x : circleX, y: newCircle !== undefined ? newCircle.y : circleY, leg: newObj }])
    setSaveSysFlag(false)
  }
  const resetElementNames = (): void => {
    let i = 1
    let j = 1
    listCircles?.forEach((circle) => {
      if (circle?.leg?.name != null) {
        circle.leg.name = 'Leg ' + String(i)
        i++
        if (circle.leg.spans != null) {
          circle.leg.spans.forEach((span) => {
            span.name = 'Sp' + String(j)
            j++
          })
        }
      }
    })
  }
  const removeLeg = (legIndex: number): void => {
    const newListCircles = cloneDeep(listCircles)
    setSaveSysFlag(false)
    if (legIndex === 1) {
      setListCircles([])
      setStartPoint({ x: 0, y: 0 })
      setNewStartPoint({ x: 0, y: 0 })
      const newActionHistory = actionHistory.slice(0, actionHistoryIndex + 1)
      newActionHistory.push({
        listCircles: [],
        containerPosition: { ...containerPosition },
        zoomScale,
        imagePosition: { ...imagePosition },
        uploadedImage,
        rotationAngle,
        imageWh: { ...imageWh }
      })
      setActionHistory(newActionHistory)
      setActionHistoryIndex(newActionHistory.length - 1)
    } else {
      const slicedCircles = newListCircles?.slice(0, legIndex)
      const listLegs = systemLayoutClass.extractLegs(slicedCircles ?? [])
      const circles = systemLayoutClass.drawLeg(startPoint.x, startPoint.y, listLegs, projectData?.unitId, mapGroundRatio, false, componentColor)
      setListCircles(circles)
      const newActionHistory = actionHistory.slice(0, actionHistoryIndex + 1)
      newActionHistory.push({
        listCircles: circles,
        containerPosition: { ...containerPosition },
        zoomScale,
        imagePosition: { ...imagePosition },
        uploadedImage,
        rotationAngle,
        imageWh: { ...imageWh }
      })
      setActionHistory(newActionHistory)
      setActionHistoryIndex(newActionHistory.length - 1)
    }
  }
  const updateLegLength = (legIndex: number, value: number): void => {
    const newListCircles = cloneDeep(listCircles)
    const circle = newListCircles?.[legIndex]
    const totalLength = systemLayoutClass.calculateTotalLength(circle?.leg?.spans ?? [])
    if (circle.leg != null && value >= 0) {
      circle.leg.length = Number(value.toFixed(2))
    }
    if (circle?.leg?.spans === undefined || circle.leg.spans == null || circle.leg.spans.length <= 0) {
      circle.leg = circle.leg ?? {}
      circle.leg.spans = [{
        name: 'Sp',
        length: Number(value.toFixed(2)),
        components: []
      }]
    }
    if (circle?.leg?.length != null) {
      if (totalLength > circle.leg.length) {
        let i: number = circle.leg.spans?.length ?? 0
        let numberSubtract = totalLength - circle.leg.length
        do {
          i = i - 1
          const subVar = numberSubtract - (circle.leg.spans?.[i].length ?? 0)
          if (subVar <= 0) {
            break
          } else {
            numberSubtract = numberSubtract - (circle?.leg?.spans?.[i].length ?? 0)
          }
        }
        while (i >= 0)
        if (numberSubtract === 0) {
          i = i - 1
        }
        if (circle.leg.spans?.[i]?.length !== undefined) {
          circle.leg.spans?.splice(i + 1, circle.leg.spans.length - i)
          circle.leg.spans[i].length = Number(((circle.leg.spans[i].length ?? 0) - numberSubtract).toFixed(2))
          circle.leg.spans[i].length = Number((circle.leg.spans[i].length ?? 0).toFixed(2))
          if (circle.leg.spans[i].length === 0 && circle.leg.spans.length > 1) circle.leg.spans.splice(i, 1)
        }
      } else {
        if (totalLength === 0 && (circle?.leg?.spans) != null) {
          const lastIndex = circle.leg.spans.length - 1
          circle.leg.spans[lastIndex].length = circle.leg.length
        } else {
          if ((circle?.leg?.spans) != null) {
            const lastIndex = circle.leg.spans.length - 1
            circle.leg.spans[lastIndex].length = Number(((circle.leg.spans[lastIndex].length ?? 0) + circle.leg.length - totalLength).toFixed(2))
          }
        }
      }
    }
    const listLegs = systemLayoutClass.extractLegs(newListCircles ?? [])
    const circles = systemLayoutClass.drawLeg(startPoint.x, startPoint.y, listLegs, projectData?.unitId, mapGroundRatio, true, componentColor)
    setListCircles(circles)
    const newActionHistory = actionHistory.slice(0, actionHistoryIndex + 1)
    newActionHistory.push({
      listCircles: circles,
      containerPosition: { ...containerPosition },
      zoomScale,
      imagePosition: { ...imagePosition },
      uploadedImage,
      rotationAngle,
      imageWh: { ...imageWh }
    })
    setActionHistory(newActionHistory)
    setActionHistoryIndex(newActionHistory.length - 1)
    setSaveSysFlag(false)
  }
  const updateLegRelativeAngle = (legIndex: number, event: any): void => {
    const newListCircles = cloneDeep(listCircles)
    const circle = newListCircles?.[legIndex]
    if (circle.leg != null && event <= 180 && event >= -180) {
      circle.leg.angle = event
    }
    if (circle.leg != null && event === '') {
      circle.leg.angle = undefined
    }
    const listLegs = systemLayoutClass.extractLegs(newListCircles ?? [])
    const circles = systemLayoutClass.drawLeg(startPoint.x, startPoint.y, listLegs, projectData?.unitId, mapGroundRatio, true, componentColor)
    setListCircles(circles)
    const newActionHistory = actionHistory.slice(0, actionHistoryIndex + 1)
    newActionHistory.push({
      listCircles: circles,
      containerPosition: { ...containerPosition },
      zoomScale,
      imagePosition: { ...imagePosition },
      uploadedImage,
      rotationAngle,
      imageWh: { ...imageWh }
    })
    setActionHistory(newActionHistory)
    setActionHistoryIndex(newActionHistory.length - 1)
    setSaveSysFlag(false)
  }
  const updateSpanLength = (legIndex: number, event: number, spanIndex: number): void => {
    const newListCircles = cloneDeep(listCircles)
    const circle = newListCircles?.[legIndex]
    if (circle.leg?.spans != null) {
      circle.leg.spans[spanIndex].length = Number(event.toFixed(2))
      circle.leg.length = systemLayoutClass.calculateTotalLength(circle.leg.spans)
    }
    const listLegs = systemLayoutClass.extractLegs(newListCircles ?? [])
    const circles = systemLayoutClass.drawLeg(startPoint.x, startPoint.y, listLegs, projectData?.unitId, mapGroundRatio, false, componentColor)
    setListCircles(circles)
    const newActionHistory = actionHistory.slice(0, actionHistoryIndex + 1)
    newActionHistory.push({
      listCircles: circles,
      containerPosition: { ...containerPosition },
      zoomScale,
      imagePosition: { ...imagePosition },
      uploadedImage,
      rotationAngle,
      imageWh: { ...imageWh }
    })
    setActionHistory(newActionHistory)
    setActionHistoryIndex(newActionHistory.length - 1)
    setSaveSysFlag(false)
  }

  const addSpan = (legIndex: number): void => {
    const newListCircles = cloneDeep(listCircles)
    const circle = newListCircles?.[legIndex]
    if (circle.leg != null) {
      const newSpan = {
        name: 'Sp',
        length: 15,
        components: []
      }
      circle.leg.spans?.push(newSpan)
      circle.leg.length = systemLayoutClass.calculateTotalLength(circle.leg?.spans ?? [])
    }
    const listLegs = systemLayoutClass.extractLegs(newListCircles ?? [])
    const circles = systemLayoutClass.drawLeg(startPoint.x, startPoint.y, listLegs, projectData?.unitId, mapGroundRatio, false, componentColor)
    const newActionHistory = actionHistory.slice(0, actionHistoryIndex + 1)
    newActionHistory.push({
      listCircles: circles,
      containerPosition: { ...containerPosition },
      zoomScale,
      imagePosition: { ...imagePosition },
      uploadedImage,
      rotationAngle,
      imageWh: { ...imageWh }
    })
    setActionHistory(newActionHistory)
    setActionHistoryIndex(newActionHistory.length - 1)
    setListCircles(circles)
    setSaveSysFlag(false)
  }

  const removeSpan = (legIndex: number, spanIndex: number, spanLength: number): void => {
    const newListCircles = cloneDeep(listCircles)
    const circle = newListCircles?.[legIndex]
    if (newListCircles !== undefined && circle?.leg != null) {
      if (spanLength === 1) {
        alert('at least 1 span per leg is required!')
      } else {
        if (newListCircles !== undefined && newListCircles.length > 0 && circle !== undefined && circle.leg !== undefined && circle.leg?.length !== undefined) {
          circle?.leg?.spans?.splice(spanIndex, 1)
          circle.leg = {
            ...circle.leg,
            length: systemLayoutClass.calculateTotalLength(newListCircles?.[legIndex]?.leg?.spans ?? [])
          }
        }
      }
      const listLegs = systemLayoutClass.extractLegs(newListCircles ?? [])
      const circles = systemLayoutClass.drawLeg(startPoint.x, startPoint.y, listLegs, projectData?.unitId, mapGroundRatio, false, componentColor)
      const newActionHistory = actionHistory.slice(0, actionHistoryIndex + 1)
      newActionHistory.push({
        listCircles: circles,
        containerPosition: { ...containerPosition },
        zoomScale,
        imagePosition: { ...imagePosition },
        uploadedImage,
        rotationAngle,
        imageWh: { ...imageWh }
      })
      setActionHistory(newActionHistory)
      setActionHistoryIndex(newActionHistory.length - 1)
      setListCircles(circles)
      setSaveSysFlag(false)
    }
  }

  const handleCloseModal = (value: number | undefined): void => {
    if (equalSpan.data?.leg?.length != null && value !== undefined) {
      const elementLength = equalSpan.data?.leg?.length / value
      equalSpan.data.leg.spans = []
      let i = 0
      do {
        equalSpan.data.leg.spans.push({
          length: Number(Math.round(elementLength * 100) / 100),
          components: []
        })
        i++
      }
      while (i < value)
      const listLegs = systemLayoutClass.extractLegs(listCircles ?? [])
      const circles = systemLayoutClass.drawLeg(startPoint.x, startPoint.y, listLegs, projectData?.unitId, mapGroundRatio, false, componentColor)
      setListCircles(circles)
      const newActionHistory = actionHistory.slice(0, actionHistoryIndex + 1)
      newActionHistory.push({
        listCircles: circles,
        containerPosition: { ...containerPosition },
        zoomScale,
        imagePosition: { ...imagePosition },
        uploadedImage,
        rotationAngle,
        imageWh: { ...imageWh }
      })
      setActionHistory(newActionHistory)
      setActionHistoryIndex(newActionHistory.length - 1)
    }
    setEqualSpan({
      visible: false,
      data: undefined
    })
    setSaveSysFlag(false)
  }
  const autoDesignForAll = async (): Promise<void> => {
    if (listCircles?.length === 0 || listCircles === undefined) {
      alert(t(KeyTranslation.Message_Legs_invalid))
    } else {
      alert(t(KeyTranslation.Confirm_AutoDesignForOneLeg))
      const sysObject = binObjectData()
      if (sysObject != null) {
        sysObject.bgImage = undefined
        try {
          setIsLoading(true)
          const res = await new SystemApi().apiV2SystemCalculationForAllPost(projectData?.unitId, sysObject)
          if (res.data.data?.message == null && res.data.data?.maxSpan != null && res.data.data.maxSpan !== undefined) {
            listCircles?.forEach((circle, i) => {
              if (i === 0) {
                return
              }
              multipleSpans(circle, {
                lengthOfLeg: res.data.data?.maxSpan?.[i - 1].lengthOfLeg,
                maxSpan: res.data.data?.maxSpan?.[i - 1].maxSpan
              })
            })
          } else if (res.data.data?.message != null) {
            const message = systemLayoutClass.handleApiCallBack(res.data.data?.message, 'all')
            alert(t(message))
          }
        } catch (error) {
          const message = systemLayoutClass.handleApiCallBack(error as any, 'all')
          alert(t(message))
        } finally {
          setIsLoading(false)
          setSaveSysFlag(false)
        }
      }
    }
  }
  const autoDesignForLeg = async (circle: CirclePoint): Promise<void> => {
    alert(t(KeyTranslation.Confirm_AutoDesignForOneLeg))
    const sysObject = binObjectData()
    if (sysObject != null) {
      sysObject.bgImage = undefined
      const data = {
        system: sysObject,
        legName: circle.leg?.name
      }
      try {
        setIsLoading(true)
        const res = await new SystemApi().apiV2SystemCalculationForIntelPost(projectData?.unitId, data)
        if (res.data.data?.message == null) {
          multipleSpans(circle, {
            lengthOfLeg: res.data.data?.lengthOfLeg,
            maxSpan: res.data.data?.maxSpan
          })
        } else if (res.data.data.message != null) {
          const message = systemLayoutClass.handleApiCallBack(res.data.data?.message, 'single')
          alert(t(message))
        }
      } catch (error) {
        const message = systemLayoutClass.handleApiCallBack(error as any, 'single')
        alert(t(message))
      } finally {
        setIsLoading(false)
        setImplementClicked(false)
        setSaveSysFlag(false)
      }
    }
  }
  const multipleSpans = (circle: CirclePoint, respon: SpanInfo): void => {
    if (circle.leg?.length !== undefined && respon.lengthOfLeg !== undefined && respon.maxSpan !== undefined) {
      const elementLength = respon.lengthOfLeg / respon.maxSpan
      circle.leg.spans = []
      let i = 0
      do {
        circle.leg.spans.push({
          name: 'Sp',
          length: Number(Math.round(elementLength * 100) / 100),
          components: []
        })
        i++
      }
      while (i < respon.maxSpan)
      const listLegs = systemLayoutClass.extractLegs(listCircles ?? [])
      const circles = systemLayoutClass.drawLeg(startPoint.x, startPoint.y, listLegs, projectData?.unitId, mapGroundRatio, false, componentColor)
      setListCircles(circles)
    }
  }
  const reCalculate = async (): Promise<void> => {
    setCalMessage({
      standing: ''
    })
    const sysObject = binObjectData()
    if ((!(sysObject?.legs != null && sysObject?.legs !== undefined && sysObject?.legs.length > 0)) || (sysObject == null || sysObject?.ropes == null || sysObject?.ropes === undefined || sysObject?.hlleAs == null || sysObject?.hlleAs === undefined)) {
      setMessage({ type: 'error', message: 'System_ParameterRequired' })
    } else {
      setMessage({ type: '', message: '' })
      try {
        setIsLoading(true)
        const data = await new SystemApi().apiV2SystemCalculationPost(sysObject as PrjSystem)
        setSystemCalculateResult(data.data.data)
        const requiredClearanceBelowThePlatformList = data?.data?.data?.standing?.spanResults?.map(item => item?.requiredClearanceBelowThePlatform).filter(value => typeof value === 'number') as number[]

        if (requiredClearanceBelowThePlatformList?.length > 0) {
          const maxValue = Math.max(...requiredClearanceBelowThePlatformList)
          const indexOfMax = requiredClearanceBelowThePlatformList.indexOf(maxValue)
          setSpanResultIndex(indexOfMax)
        }
        setSrlLengthAtFall({
          standing: data.data.data?.standing?.srlLengthAtFall
        })
        const newCalMesssage = { ...calMessage } as any
        const newPeakTensionSpan = { ...peakTensionSpan } as any
        Object.keys(data.data.data ?? {}).forEach((key) => {
          const value = (data.data.data as any)?.[key]
          if (value?.errorCode != null) {
            switch (value.errorCode) {
              case 'The_PPE_Connecting_Device_selected_is_not_long_enough_':
                newCalMesssage[key] = ('The_PPE_Connecting_Device_selected_is_not_long_enough_')
                break
              case 'The_HLL_System_Tension_is_exceeding_':
                newCalMesssage[key] = ('The_HLL_System_Tension_is_exceeding_')
                break
              case 'The_system_as_configured_does_not_protect_the_worker_':
                newCalMesssage[key] = ('The_system_as_configured_does_not_protect_the_worker_')
                break
              case 'Message_NoCalculationForNoLimit':
                alert(t(KeyTranslation.Message_NoCalculationForNoLimit))
                break
              default:
                newCalMesssage[key] = ('SystemCalculationUnSuccessful')
            }
          }
          if (value?.isRestraintSystem === true) {
            newCalMesssage[key] = `System ${key} is restraint.`
          }
        })
        Object.keys(data.data.data ?? {}).forEach((key) => {
          const value = (data.data.data as any)?.[key]
          if (value?.spanResults != null) {
            const listSpans = value?.spanResults
            newPeakTensionSpan[key] = value.spanResults[0].spanName
            let maxPeakHLLTension = listSpans[0].peakhllTension
            for (let i = 1; i < listSpans.length; i++) {
              if (maxPeakHLLTension !== undefined && maxPeakHLLTension < parseFloat(String(listSpans[i].peakhllTension))) {
                maxPeakHLLTension = parseFloat(String(listSpans[i].peakhllTension))
                newPeakTensionSpan[key] = value.spanResults[i].spanName
              }
            }
          }
        })
        setPeakTensionSpan(newPeakTensionSpan)
        setCalMessage(newCalMesssage)
        if ((data.data.data?.standing?.minClearanceRemaining ?? 0) < 0) {
          alert(`${t(KeyTranslation.Common_Standing)}: ${t(KeyTranslation.Clearance_Remaining_Warning_Message)}`)
          setWarning({ standing: `${t(KeyTranslation.Clearance_Remaining_Warning_Message)}` })
        }
        if (data.status === 209) {
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          alert(t(`${(data.data.error as any)}`))
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          const errorStanding = t(`${(data.data.error as any)}`)
          setCalMessage({ ...calMessage, standing: errorStanding })
        }
        toast.success(t(KeyTranslation.SystemCalculationSuccessful))
        if (data.data.error === null) {
          setCalMessage({ ...calMessage, standing: '' })
        }
      } catch (er: any) {
        const error = systemLayoutClass.handleRecalculateMessage(er.response.data.error)
        setMessage({ type: 'error', message: error })
        const errorStanding = systemLayoutClass.handleStandingMessage(er.response.data.error)
        setCalMessage({ ...calMessage, standing: errorStanding })
      } finally {
        setIsLoading(false)
      }
    }
  }
  const saveSystem = async (): Promise<void> => {
    const sysObject = binObjectData()
    setSaveSysFlag(true)
    let dataURL = null
    if (stageRef.current != null) {
      dataURL = stageRef.current.toDataURL({
        quality: 1
      })
    }
    if (sysObject != null) {
      sysObject.systemLayoutImage = dataURL
    }
    try {
      setIsLoading(true)
      await new SystemApi().apiV2SystemPut(projectData?.unitId, sysObject)
      setMessage({ type: 'susses', message: 'System_UpdateSuccess' })
    } catch (er: any) {
      const error = systemLayoutClass.handleSaveSystemMessage(er.response.data.Error)
      setMessage({ type: 'error', message: error })
    } finally {
      setIsLoading(false)
    }
  }
  const smartComponent = async (): Promise<void> => {
    const sysObject = binObjectData()
    if ((sysObject?.legs != null && sysObject?.legs?.length < 1) || sysObject?.legs === undefined) {
      setMessage({ type: 'error', message: 'Message_Legs_invalid' })
    } else {
      setMessage({ type: '', message: '' })
      try {
        setIsLoading(true)
        const { data } = await new SystemApi().apiV2SystemSmartComponentDesignationPost(sysObject as SmartComponentDesignationDto)
        const listLegs = systemLayoutClass.extractLegs(listCircles ?? [])
        if (data.data != null && data != null) {
          data.data.forEach((dataItem, i) => {
            if (dataItem?.spans != null) {
              dataItem.spans.forEach((spanItem, j) => {
                const legSpans = listLegs?.[i]?.spans
                if (legSpans !== undefined && legSpans !== null && listLegs !== undefined) {
                  listLegs[i].spans = legSpans.map((legSpan: any, k: number) => {
                    if (k === j) {
                      return { ...legSpan, components: spanItem.components }
                    }
                    return legSpan
                  })
                }
              })
            }
            const circles = systemLayoutClass.drawLeg(startPoint.x, startPoint.y, listLegs, projectData?.unitId, mapGroundRatio, false, componentColor)
            setListCircles(circles)
            setMessage({ type: 'susses', message: 'System_Smart_Component_Designation' })
          })
        } else {
          setMessage({ type: 'error', message: 'Smart Component designed Unsuccessfully!' })
        }
      } catch (er: any) {
        setMessage({ type: 'error', message: 'Smart Component designed Unsuccessfully!' })
      } finally {
        setIsLoading(false)
        setSaveSysFlag(false)
      }
    }
  }
  const updateComponent = (span?: CustomSpanDto, index?: number, spanIndex?: number): void => {
    const newListCircles = cloneDeep(listCircles)
    if (index !== undefined && spanIndex !== undefined) {
      if (index === 0) {
        const circle = newListCircles[index + 1]
        if (circle?.leg != null && Array.isArray(circle.leg.spans) && span !== undefined) {
          circle.leg.spans[spanIndex] = span
        }
      } else {
        const circle = newListCircles[index]
        if (circle?.leg != null && Array.isArray(circle.leg.spans) && span !== undefined) {
          circle.leg.spans[spanIndex] = span
        }
      }
    }
    const listLegs = systemLayoutClass.extractLegs(newListCircles ?? [])
    const circles = systemLayoutClass.drawLeg(startPoint.x, startPoint.y, listLegs, projectData?.unitId, mapGroundRatio, false, componentColor)
    const newActionHistory = actionHistory.slice(0, actionHistoryIndex + 1)
    newActionHistory.push({
      listCircles: circles,
      containerPosition: { ...containerPosition },
      zoomScale,
      imagePosition: { ...imagePosition },
      uploadedImage,
      rotationAngle,
      imageWh: { ...imageWh }
    })
    setActionHistory(newActionHistory)
    setActionHistoryIndex(newActionHistory.length - 1)
    setListCircles(circles)
  }
  const binObjectData = (): SystemInfoDto | undefined => {
    if (systemData !== null) {
      const submitData = systemData
      const newListCircles = cloneDeep(listCircles)
      const listLegs = systemLayoutClass.extractLegs(newListCircles ?? [])
      listLegs?.forEach((leg) => {
        delete leg.coordinateX
        delete leg.coordinateY
        delete leg.realAngle
        delete leg.spanType
        leg.spans?.forEach((span: CustomSpanDto) => {
          delete span.x
          delete span.y
        })
      })
      if (uploadedImage != null && imageWh.width > 100) {
        const backgroundImage = {
          width: imageWh.width,
          height: imageWh.height,
          position: `${imagePosition.x ?? 0} ${imagePosition.y ?? 0}`,
          src: uploadedImage.currentSrc,
          originalCanvasWidth: canvasWidth,
          originalCanvasHeight: canvasWidth,
          isLockBackground: showIcons,
          userLockBg: '',
          lockByAdmin: false
        }
        if (systemData.bgImage != null && JSON.parse(systemData.bgImage).isLockBackground === true) {
          // backgroundImage.isLockBackground = JSON.parse(systemData.bgImage).isLockBackground
          backgroundImage.lockByAdmin = JSON.parse(systemData.bgImage).lockByAdmin
          submitData.bgImage = JSON.stringify(backgroundImage)
        } else {
          submitData.bgImage = JSON.stringify(backgroundImage)
        }
      }
      submitData.legs = listLegs
      submitData.numberOfUsers = users
      submitData.userWeight = selectedUserMass
      submitData.realSquare = gridSquareWidth
      if (newStartPoint.x !== 0 && newStartPoint.y !== 0 && (listCircles?.length ?? 1) >= 1) {
        submitData.startPoint = '{"x":"' + String(newStartPoint.x) + '","y":"' + String(newStartPoint.y) + '","mapGroundRatio":"' + String(mapGroundRatio) + '","originalCanvasWidth":"' + String(canvasWidth) + '","originalCanvasHeight":"' + String(canvasWidth) + '"}'
      } else {
        submitData.startPoint = null
      }
      if (submitData.ppEs !== undefined && submitData.ppEs != null) {
        submitData.ppEs.forEach(function (item) {
          item.systemId = submitData.id
        })
      }
      if (submitData.harnesses !== undefined && submitData.harnesses != null) {
        submitData.harnesses.forEach(function (item) {
          item.systemId = submitData.id
        })
      }
      return submitData
    }
  }
  const handleSelectCircle = (): void => {
    if (!select) {
      setSelect(true)
      setDragging(false)
      setDrawMode(false)
    } else {
      setSelect(false)
    }
  }
  const handleMoveOutClick = (): void => {
    if (!dragging) {
      setDragging(true)
      setDrawMode(false)
      setSelect(false)
    } else {
      setDragging(false)
    }
  }
  const handleContainerPositon = (e: any): void => {
    const newContainerPosition = {
      x: e.target.x(),
      y: e.target.y()
    }
    const updatedStartPoint = {
      x: startPoint.x + Number(newContainerPosition.x),
      y: startPoint.y + Number(newContainerPosition.y)
    }
    // setpositionXY(newContainerPosition)
    setNewStartPoint(updatedStartPoint)
    setContainerPosition(newContainerPosition)
    const newActionHistory = actionHistory.slice(0, actionHistoryIndex + 1)
    newActionHistory.push({
      listCircles: [...listCircles],
      containerPosition: newContainerPosition,
      zoomScale,
      imagePosition: { ...imagePosition },
      uploadedImage,
      rotationAngle,
      imageWh: { ...imageWh }
    })
    setActionHistory(newActionHistory)
    setActionHistoryIndex(newActionHistory.length - 1)
    setSaveSysFlag(false)
  }
  useEffect(() => {
    void productRefetch()
  }, [])
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent): void => {
      if ((event.ctrlKey || event.metaKey) && event.key === 'z') {
        handleUndoAction()
      }
      if ((event.ctrlKey || event.metaKey) && event.shiftKey && event.key === 'z') {
        handleRedoAction()
      }
    }
    document.addEventListener('keydown', handleKeyDown)
    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [actionHistoryIndex])
  useEffect(() => {
    resetElementNames()
  }, [listCircles])
  useEffect(() => {
    updateStageWidth()
    window.addEventListener('resize', updateStageWidth)
    return () => {
      window.removeEventListener('resize', updateStageWidth)
    }
  }, [updateStageWidth])
  useEffect(() => {
    if (canvasWidth > 0) {
      let circles
      if (systemData.startPoint != null && systemData.startPoint !== '') {
        const parsedStartPoint = JSON.parse(systemData.startPoint)
        adjustRatioWidthRef = parseFloat(canvasWidth.toString()) / parseFloat(parsedStartPoint.originalCanvasWidth)
        const startx = parseFloat(parsedStartPoint.x) * adjustRatioWidthRef
        const starty = parseFloat(parsedStartPoint.y) * adjustRatioWidthRef
        setStartPoint({ x: startx, y: starty })
        setNewStartPoint({ x: startx, y: starty })
        if (systemData.legs !== undefined && systemData.legs !== null && systemData.legs?.length > 0) {
          circles = systemLayoutClass.drawLeg(startx, starty, systemData.legs, projectData?.unitId, mapGroundRatio, true, componentColor)
          setListCircles(circles)
          const newActionHistory = actionHistory.slice(0, actionHistoryIndex + 1)
          newActionHistory.push({
            listCircles: circles,
            containerPosition: { ...containerPosition },
            zoomScale,
            imagePosition: { ...imagePosition },
            uploadedImage,
            rotationAngle,
            imageWh: { ...imageWh }
          })
          setActionHistory(newActionHistory)
          setActionHistoryIndex(newActionHistory.length - 1)
        } else {
          circles = systemLayoutClass.drawLeg(startx, starty, null, projectData?.unitId ?? 0, mapGroundRatio, true, componentColor)
          setListCircles(circles)
          const newActionHistory = actionHistory.slice(0, actionHistoryIndex + 1)
          newActionHistory.push({
            listCircles: circles,
            containerPosition: { ...containerPosition },
            zoomScale,
            imagePosition: { ...imagePosition },
            uploadedImage,
            rotationAngle,
            imageWh: { ...imageWh }
          })
          setActionHistory(newActionHistory)
          setActionHistoryIndex(newActionHistory.length - 1)
        }
      }
      if (systemData.bgImage != null) {
        if (adjustRatioWidthRef === 1) adjustRatioWidthRef = parseFloat(canvasWidth.toString()) / parseFloat(JSON.parse(systemData.bgImage).originalCanvasWidth)
        setShowIcons(JSON.parse(systemData.bgImage).isLockBackground)
        setUserLock(JSON.parse(systemData.bgImage).userLockBg)
        const imageElement = new window.Image()
        imageElement.src = JSON.parse(systemData.bgImage).src
        imageElement.onload = () => {
          setUploadedImage(imageElement)
        }
        const positon = JSON.parse(systemData.bgImage).position
        const positionValues = positon.split(' ')
        setImagePosition({
          x: parseFloat(positionValues[0]) * adjustRatioWidthRef,
          y: parseFloat(positionValues[1]) * adjustRatioWidthRef
        })
        setImageWh({
          width: JSON.parse(systemData.bgImage).width * adjustRatioWidthRef,
          height: JSON.parse(systemData.bgImage).height * adjustRatioWidthRef
        })
        setBgOriginalSize({
          width: JSON.parse(systemData.bgImage).width * adjustRatioWidthRef,
          height: JSON.parse(systemData.bgImage).height * adjustRatioWidthRef
        })
        const newActionHistory = actionHistory.slice(0, actionHistoryIndex + 1)
        newActionHistory.push({
          listCircles: ([...circles ?? []]),
          containerPosition: { ...containerPosition },
          uploadedImage: imageElement,
          imagePosition: {
            x: parseFloat(positionValues[0]) * adjustRatioWidthRef,
            y: parseFloat(positionValues[1]) * adjustRatioWidthRef
          },
          zoomScale,
          rotationAngle,
          imageWh: {
            width: JSON.parse(systemData.bgImage).width * adjustRatioWidthRef,
            height: JSON.parse(systemData.bgImage).height * adjustRatioWidthRef
          }
        })
        setActionHistory(newActionHistory)
        setActionHistoryIndex(newActionHistory.length - 1)
      }
    }
  }, [canvasWidth])
  const handleMouseMove = (e: any): void => {
    const mousePos = { x: e.evt.offsetX, y: e.evt.offsetY }
    if (listCircles.length >= 1) {
      const lastCircle = listCircles[listCircles.length - 1]
      const { angle, length } = systemLayoutClass.calculateAngleAndLength(mousePos, lastCircle)
      const realLength = systemLayoutClass.ConvertFromCanvasPixelsToRealMeter(length, projectData?.unitId, mapGroundRatio)
      setMousePosition({ x: Number(angle.toFixed(2)), y: Number(realLength.toFixed(2)) })
    } else {
      setMousePosition(mousePos)
    }
  }
  return (
    <>
      {message.message !== '' && (
        <Alert sx={{ mt: 8 }} severity={message.type === 'error' ? 'error' : 'success'}>{t(message.message)}</Alert>
      )}
      <Grid className='system' container spacing={4}>
        <Grid item xs={8} className='system-layout'>
          <Box className="system-header" >
            <Typography sx={{ fontSize: '20px', fontWeight: '600', textTransform: 'uppercase', color: '#1E1E96' }}>{t(KeyTranslation.System_SystemLayout)}</Typography>
            <Box display={'flex'} gap={'10px'}>
              <label>
                {`${t(KeyTranslation.One_Grid_Square_Width)} ${projectData?.unitId === 2 ? '(ft)' : '(m)'}`}
                <input
                  type="number"
                  style={{ width: '70px', marginLeft: '5px' }}
                  value={gridSquareWidth}
                  min={0}
                  onChange={(e) => {
                    setSaveSysFlag(false)
                    setGridSquareWidth(Number(e.target.value) === 0 ? 1 : Number(e.target.value))
                  }}
                />
              </label>
              <label>
                {`${t(KeyTranslation.System_Canvas)} ${projectData?.unitId === 2 ? '(ft)' : '(m)'}`}
                <input
                  style={{ width: '140px', marginLeft: '5px' }}
                  type="number"
                  value={gridSquareWidth === 0 ? 30 : gridSquareWidth * 30}
                  readOnly
                  disabled
                />
              </label>
            </Box>
            <Button
              onClick={() => { void saveSystem() }}
              disabled={saveSysFlag}
              variant="contained">
              {t(KeyTranslation.System_SaveSystem)}</Button>
          </Box>
          <Box className='system-option'>
            <Box display={'flex'}>
              <Typography
                onClick={() => {
                  setShowMap(false)
                }}
                className={` ${!showMap ? 'optional' : 'option'}`}
              >
                {t(KeyTranslation.System_Blank_Grid)}
              </Typography>
              {!showIcons && (
                <>
                  <Typography
                    onClick={() => {
                      setShowMap(true)
                    }}
                    className={` ${showMap ? 'optional' : 'option'}`}
                  >
                    {t(KeyTranslation.System_GoogleMap)}
                  </Typography>
                </>
              )}
            </Box>
            {showMap && (
              <Box display={'flex'} gap={2}>
                <Autocomplete
                  onLoad={(autocomplete) => {
                    setAutocomplete(autocomplete)
                  }}
                  onPlaceChanged={handlePlaceSelect}
                >
                  <TextField
                    label="City or town"
                    size="small"
                  />
                </Autocomplete>
                <Button size='small' onClick={() => { handleSelectMap() }} variant='outlined'>
                  {t(KeyTranslation.Select_map)}
                </Button>
              </Box>
            )
            }
            {!showMap && !showIcons && (
              <Button size='small' component="label" variant="outlined" startIcon={<CloudUploadIcon />}>
                {t(KeyTranslation.Common_UploadFile)}
                <VisuallyHiddenInput
                  onChange={(e) => { handleFileChange(e) }}
                  accept=".jpg, .jpeg, .png"
                  type="file"
                />
              </Button>
            )
            }
          </Box>
          <Box className="system-bottom" display={'flex'} gap={2}>
            <Box display={'flex'} flexDirection={'column'} gap={2}>
              <Tooltip title={t(KeyTranslation.Draw)} placement="left-start">
                <Button sx={{ p: 1, minWidth: '30px' }} variant={drawMode ? 'contained' : 'outlined'}
                  onClick={() => {
                    setDrawMode(!drawMode)
                    setDragging(false)
                    setSelect(false)
                    setActiveIcon('')
                  }} >
                  <CreateOutlinedIcon />
                </Button>
              </Tooltip>
              <Tooltip title={t(KeyTranslation.Common_Select)} placement="left-start">
                <Button sx={{ p: 1, minWidth: '30px' }} variant={select ? 'contained' : 'outlined'}
                  onClick={handleSelectCircle}
                >
                  <TouchAppOutlinedIcon />
                </Button>
              </Tooltip>
              <Tooltip title={t(KeyTranslation.Common_Move)} placement="left-start">
                <Button sx={{ p: 1, minWidth: '30px' }} variant={dragging ? 'contained' : 'outlined'}
                  onClick={handleMoveOutClick}
                >
                  <ControlCameraOutlinedIcon />
                </Button>
              </Tooltip>
              <Tooltip title={t(KeyTranslation.Common_Undo)} placement="left-start">
                <Button variant='outlined' sx={{ p: 1, mt: 2, minWidth: '30px' }} disabled={actionHistoryIndex === -1}
                  onClick={() => { if (actionHistoryIndex !== -1) handleUndoAction() }}
                >
                  <RotateLeftOutlinedIcon />
                </Button>
              </Tooltip>
              <Tooltip title={t(KeyTranslation.Common_Redo)} placement="left-start">
                <Button variant='outlined' sx={{ p: 1, minWidth: '30px' }}
                  onClick={handleRedoAction}
                >
                  <RotateRightOutlinedIcon />
                </Button>
              </Tooltip>
            </Box>
            <Box position={'relative'}>
              <Stage ref={stageRef} onClick={handleCanvasClick} width={canvasWidth} height={canvasWidth}
                onMouseMove={handleMouseMove}
              >
                <Layer>
                  {uploadedImage != null && (
                    <Image
                      draggable={draggable}
                      x={imagePosition.x}
                      y={imagePosition.y}
                      onDragEnd={(e) => {
                        handleMoveImage(e)
                      }}
                      image={uploadedImage}
                      width={imageWh.width}
                      height={imageWh.height}
                      rotation={rotationAngle}
                      ref={imageRef}
                    />
                  )}
                  {Array.from({ length: numCols }, (_, i) => (
                    <Line
                      key={`vertical-${i}`}
                      points={[i * cellSize, 0, i * cellSize, numRows * cellSize
                      ]}
                      stroke="lightgray"
                      strokeWidth={1}
                    />
                  ))}
                  {Array.from({ length: numRows }, (_, i) => (
                    <Line
                      key={`horizontal-${i}`}
                      points={[0, i * cellSize, numCols * cellSize, i * cellSize
                      ]}
                      stroke="lightgray"
                      strokeWidth={1}
                    />
                  ))}
                  <Group
                    draggable={dragging}
                    x={containerPosition.x}
                    y={containerPosition.y}
                    onDragEnd={(e) => {
                      handleContainerPositon(e)
                    }}
                  >
                    {listCircles?.map((circle, index) => {
                      if (index < listCircles.length - 1) {
                        const nextCircle = listCircles[index + 1]
                        const lineStartX = (circle.x - startPoint.x) * scaleFactor + startPoint.x
                        const lineStartY = (circle.y - startPoint.y) * scaleFactor + startPoint.y
                        const lineEndX = (nextCircle.x - startPoint.x) * scaleFactor + startPoint.x
                        const lineEndY = (nextCircle.y - startPoint.y) * scaleFactor + startPoint.y
                        const isLineClicked = clicked && index === clickedIndex
                        return (
                          <React.Fragment key={`line-${index}`}>
                            <Line
                              points={[lineStartX, lineStartY, lineEndX, lineEndY]}
                              stroke={isLineClicked ? highLightColor : 'red'}
                              strokeWidth={1}
                            />
                            <React.Fragment key={`transparent-${index}`}>
                              <Line
                                points={[lineStartX, lineStartY, lineEndX, lineEndY]}
                                stroke={'transparent'}
                                strokeWidth={10}
                                onClick={() => {
                                  if (select) {
                                    if (index !== clickedIndex) {
                                      handleLineClick(index)
                                      setOpenAccordionIndex(index + 1)
                                    } else {
                                      setClickedIndex(undefined)
                                      setOpenAccordionIndex(-1)
                                    }
                                  }
                                }}
                              />
                            </React.Fragment>
                          </React.Fragment>
                        )
                      }
                      return null
                    })}
                    {listCircles !== undefined && listCircles.length > 0 && (
                      <Circle
                        x={(listCircles?.[0].x - startPoint.x) * scaleFactor + startPoint.x}
                        y={(listCircles?.[0].y - startPoint.y) * scaleFactor + startPoint.y}
                        radius={4}
                        fill={listCircles?.[0].color ?? 'red'}
                        onClick={() => { if (select && listCircles.length > 1) drawSelectedCircle(listCircles?.[0].x, listCircles?.[0].y, listCircles?.[1].leg?.spans?.[0] ?? {}, 0, 0, true, false, false, (listCircles?.[0].leg?.angle ?? 0)) }}
                      />
                    )}
                    {listCircles?.map((circle, index) => {
                      return <React.Fragment key={`circle-${index}`}>
                        {circle.leg?.spans?.map((span: CustomSpanDto, spanIndex) => {
                          if (span.x !== undefined && span.y !== undefined) {
                            endPoint = { x: span.x, y: span.y }
                            return (
                              <Circle
                                key={`span-circle-${index}-${spanIndex}`}
                                x={(span.x - startPoint.x) * scaleFactor + startPoint.x}
                                y={(span.y - startPoint.y) * scaleFactor + startPoint.y}
                                radius={4}
                                fill={span.color !== undefined ? span.color : drawColor}
                                onClick={() => {
                                  const isEndPoint = span.x === endPoint.x && span.y === endPoint.y
                                  const nextCircle = listCircles?.[index + 1]
                                  let angle = 180
                                  if (nextCircle === null || nextCircle === undefined) {
                                    angle = 180
                                  } else {
                                    if (Number(circle.leg?.spans?.length) > 1) {
                                      if (spanIndex === Number(circle.leg?.spans?.length) - 1) { angle = (nextCircle?.leg?.angle ?? 0) } else { angle = 180 }
                                    } else {
                                      angle = (nextCircle?.leg?.angle ?? 0)
                                    }
                                  }
                                  const isConner = Math.abs(angle) !== 180
                                  const x = ((span.x ?? 0) - startPoint.x) * scaleFactor + startPoint.x
                                  const y = ((span.y ?? 0) - startPoint.y) * scaleFactor + startPoint.y
                                  drawSelectedCircle(x, y, span, index, spanIndex, false, isEndPoint, isConner, angle)
                                }}
                              />
                            )
                          }
                        })}
                      </React.Fragment>
                    }
                    )}
                    {listSpan?.map((span, index) => {
                      if (index < listSpan.length - 1) {
                        const nextSpan = listSpan[index + 1]
                        const lineStartX = ((span.x ?? 0) - startPoint.x) * scaleFactor + startPoint.x
                        const lineStartY = ((span.y ?? 0) - startPoint.y) * scaleFactor + startPoint.y
                        const lineEndX = ((nextSpan.x ?? 0) - startPoint.x) * scaleFactor + startPoint.x
                        const lineEndY = ((nextSpan.y ?? 0) - startPoint.y) * scaleFactor + startPoint.y
                        return (
                          <React.Fragment key={`transparent-${index}`}>
                            <Line
                              points={[lineStartX, lineStartY, lineEndX, lineEndY]}
                              stroke={'transparent'}
                              strokeWidth={0}
                            />
                            <Text
                              x={(lineStartX + lineEndX) / 2 + 5}
                              y={(lineStartY + lineEndY) / 2}
                              text={span.name ?? ''}
                              fontSize={10}
                              fill="red"
                              align="center"
                            />
                          </React.Fragment>
                        )
                      }
                    })}
                  </Group>
                </Layer>
              </Stage>
              {showMap && (
                <div id="google-map" style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}></div>
              )}
              {Boolean(anchorEl.visible) && (
                <ModalComponent
                  spanData={anchorEl}
                  products={product?.data}
                  pointSelected={pointSelected}
                  // circles={listCircles}
                  onClose={() => {
                    setAnchorEl({
                      visible: false,
                      x: undefined,
                      y: undefined,
                      span: undefined,
                      index: undefined,
                      spanIndex: undefined,
                      angle: undefined,
                      isFirstPoint: false,
                      isLastPoint: false
                    })
                  }}
                  updateComponent={(span, index, spanIndex) => { updateComponent(span, index, spanIndex) }}
                />
              )}
              {!showMap && uploadedImage != null && (
                <Box className={'actionImage'}>
                  {!showIcons && (
                    <>
                      <Tooltip title={t(KeyTranslation.Common_LockImage)} placement="top-start">
                        <Button sx={{ p: 0, minWidth: '25px' }} variant={'outlined'}
                          onClick={() => {
                            handleLock()
                          }} >
                          <LockIcon />
                        </Button>
                      </Tooltip>
                      <Tooltip title={t(KeyTranslation.Common_ZoomIn)} placement="top-start">
                        <Button sx={{ p: 0, minWidth: '25px' }} variant={activeIcon === 'zoomIn' ? 'contained' : 'outlined'}
                          onClick={() => {
                            setActiveIcon('zoomIn')
                            if (uploadedImage != null) {
                              handleZoom('in')
                            }
                          }} >
                          <ZoomInIcon />
                        </Button>
                      </Tooltip>
                      <Tooltip title={t(KeyTranslation.Common_ZoomOut)} placement="top-start">
                        <Button sx={{ p: 0, minWidth: '25px' }} variant={activeIcon === 'zoomOut' ? 'contained' : 'outlined'}
                          onClick={() => {
                            setActiveIcon('zoomOut')
                            if (uploadedImage != null) {
                              handleZoom('out')
                            }
                          }} >
                          <ZoomOutIcon />
                        </Button>
                      </Tooltip>
                      <Tooltip title={t(KeyTranslation.Common_DeleteImage)} placement="top-start">
                        <Button sx={{ p: 0, minWidth: '25px' }} variant={'outlined'}
                          onClick={() => {
                            handleDeleteImage()
                          }} >
                          <ClearIcon />
                        </Button>
                      </Tooltip>
                      <Tooltip title={t(KeyTranslation.Common_MoveImage)} placement="top-start">
                        <Button sx={{ p: 0, minWidth: '25px' }} variant={activeIcon === 'moveImage' ? 'contained' : 'outlined'}
                          onClick={() => {
                            setActiveIcon('moveImage')
                            handleMoveImageClick()
                          }} >
                          <ControlCameraOutlinedIcon />
                        </Button>
                      </Tooltip>
                      <Tooltip title={t(KeyTranslation.Common_Rotate)} placement="top-start">
                        <label>
                          <input
                            style={{ width: '60px', padding: '3px' }}
                            type="number"
                            value={rotationAngle}
                            onChange={(e) => {
                              if (uploadedImage != null) {
                                const newRotation = Number(e.target.value)
                                handleRotationChange(newRotation)
                              }
                            }}
                          />
                        </label>
                      </Tooltip>
                    </>
                  )}
                  {showIcons && (
                    <LockOpenIcon
                      onClick={handleUnlock}
                      className={'unActive'}
                    />
                  )}
                </Box>
              )}
              {!showMap && (
                <Box className="angle-length">
                  <Box display={'flex'} gap={5}>
                    <label style={{ fontSize: '10px' }}>
                      {t(KeyTranslation.System_Angle)}:
                      <input disabled value={mousePosition.x * -1} className='input-al' readOnly type="number" />
                    </label>
                    <label style={{ fontSize: '10px' }}>
                      {t(KeyTranslation.System_Length)}{projectData?.unitId === 2 ? '(ft)' : '(m)'}:
                      <input disabled value={mousePosition.y} className='input-al' readOnly type="number" />
                    </label>
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        </Grid>
        <Grid item xs={4} className='system-accordion'>
          <Accordion
            defaultExpanded={true}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
              className='accordion-header'
            >
              <Typography sx={{ width: '33%', flexShrink: 0, textTransform: 'uppercase' }}>
                {t(KeyTranslation.System_ReCalculate)}
              </Typography>
            </AccordionSummary>
            <Box>
              <AccordionDetails>
                <Box sx={{ display: 'flex', gap: 4, justifyContent: 'end' }}>
                  <Button size='small' onClick={() => { void reCalculate() }} variant="outlined">{t(KeyTranslation.System_ReCalculate)}</Button>
                  <Button size='small' onClick={() => { void smartComponent() }} variant="outlined">{t(KeyTranslation.System_smartComponent)}</Button>
                </Box>
                <Box sx={{ mt: 4 }}>
                  <FormControl>
                    <RadioGroup
                      row
                      value={currentUserPosture}
                      onChange={(event) => {
                        setCurrentUserPosture((event.target.value))
                      }}
                    >
                      <FormControlLabel
                        value='standing'
                        control={<Radio />}
                        label={t(KeyTranslation.Common_Standing)}
                      />
                    </RadioGroup>
                  </FormControl>
                </Box>
                <Box sx={{ mt: 4 }}>
                  <FormControl fullWidth>
                    <InputLabel>{t(KeyTranslation.Select)}</InputLabel>
                    <Select
                      value={spanResultIndex}
                      onChange={(e) => { setSpanResultIndex(Number(e.target.value)) }}
                      input={<OutlinedInput label={t(KeyTranslation.Select)} />}
                    >
                      {systemLayoutClass.getDataSelectedFromPos(currentUserPosture, systemCalculateResult)?.spanResults != null
                        ? (
                            systemLayoutClass.getDataSelectedFromPos(currentUserPosture, systemCalculateResult)?.spanResults?.map((span: SpanResult, index: number) => (
                            <MenuItem key={index} value={index} selected={index === spanResultIndex}>
                              {span.spanName}
                            </MenuItem>
                            ))
                          )
                        : (
                          <MenuItem>
                            {t(KeyTranslation.No_Data_Found)}
                          </MenuItem>
                          )}
                    </Select>
                  </FormControl>
                </Box>
                <Box
                  sx={{ mt: 4 }}
                >
                  <Box display={'flex'} justifyContent={'space-between'}>
                    <Typography variant='caption'>
                      {`${t(KeyTranslation.System_DistanceFromGround)}: `}
                    </Typography>
                    <Typography width={'50%'} style={{ color: 'rgba(0,0,0,0.87)' }}>{`${distanceFromGround} ${projectData?.unitId === 2 ? 'ft' : 'm'}`}</Typography>
                  </Box>
                  <Box display={'flex'} justifyContent={'space-between'}>
                    <Typography variant='caption'>
                      {`${t(KeyTranslation.System_TotalFallDistance)}: `}
                    </Typography>
                    <Typography width={'50%'} style={{ color: 'rgba(0,0,0,0.87)' }}>{`${systemLayoutClass.getTotalFallDistance(systemLayoutClass.getSpanDataSelectedFromSpan(currentUserPosture, spanResultIndex ?? 0, systemCalculateResult)) ?? 0} ${projectData?.unitId === 2 ? 'ft' : 'm'}`}</Typography>
                  </Box>

                  <Box display={'flex'} justifyContent={'space-between'}>
                    <Typography width={'50%'} variant='caption' className='ellipsisBaseComponent'>
                      {`${t(KeyTranslation.System_RequiredClearanceBelowThePlatform)}: `}
                    </Typography>
                    <Typography width={'50%'} style={{ color: 'rgba(0,0,0,0.87)' }}>{`${systemLayoutClass.getSpanDataSelectedFromSpan(currentUserPosture, spanResultIndex ?? 0, systemCalculateResult)?.requiredClearanceBelowThePlatform ?? 0} ${projectData?.unitId === 2 ? 'ft' : 'm'}`}</Typography>
                  </Box>
                  <Box display={'flex'} justifyContent={'space-between'}>
                    <Typography variant='caption'>
                      {`${t(KeyTranslation.System_PeakHlllTension)}: `}
                    </Typography>
                    <Typography width={'50%'} style={{ color: 'rgba(0,0,0,0.87)' }}>{`${systemLayoutClass.getSpanDataSelectedFromSpan(currentUserPosture, spanResultIndex ?? 0, systemCalculateResult)?.peakhllTension ?? 0} ${projectData?.unitId === 2 ? ' lb' : ' kN'}`}</Typography>
                  </Box>
                  <Box display={'flex'} justifyContent={'space-between'}>
                    <Typography variant='caption'>
                      {`${t(KeyTranslation.System_PeakTensionSpan)}: `}
                    </Typography>
                    <Typography width={'50%'} style={{ color: 'rgba(0,0,0,0.87)' }}>{`${peakTensionSpan?.[currentUserPosture] ?? ''}`}</Typography>
                  </Box>
                </Box>
                {calMessage.standing !== '' && (
                  <Alert sx={{ mt: 2 }} severity="error">{`${t(KeyTranslation.Common_Standing)}: ${t(calMessage.standing)}`}</Alert>
                )}
                <Box sx={{ mt: 2 }} display={'flex'} justifyContent={'end'} gap={4}>
                  <Button size='small' onClick={() => { setOutputModal(true) }} variant="outlined" disabled={systemCalculateResult === undefined}>{t(KeyTranslation.System_OutputDiagram)}</Button>
                  <Button size='small' variant="outlined" disabled={systemCalculateResult === undefined} onClick={() => {
                    void saveSystem()
                    setVisibleReport(true)
                  }}>
                    {t(KeyTranslation.New_Report)}
                  </Button>
                </Box>
              </AccordionDetails>
            </Box>
          </Accordion>
          <Accordion
            defaultExpanded={true}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography sx={{ width: '33%', flexShrink: 0, textTransform: 'uppercase' }}>
                {t(KeyTranslation.System_NumberUser)}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 4,
                  justifyContent: 'space-between'
                }}
              >
                <Typography variant='body2' component={'span'}>
                  {t(KeyTranslation.System_NumberUser)}
                </Typography>
                <Box display={'flex'} width={'50%'}>
                  <TextField
                    inputProps={{
                      min: 1,
                      max: 4
                    }}
                    fullWidth
                    value={users}
                    onChange={(event) => {
                      const value = Number(event.target.value)
                      if (value >= 1 && value <= 4) {
                        setUsers(value)
                        setSaveSysFlag(false)
                      }
                    }}
                    size="small"
                    type="number"
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 4,
                  justifyContent: 'space-between'
                }}
              >
                <Typography variant='body2' component={'span'}>
                  {t(KeyTranslation.System_UserWeight)}
                </Typography>
                <Box width={'50%'}>
                  <FormControl>
                    <RadioGroup
                      sx={{ flexDirection: 'row' }}
                      value={selectedUserMass}
                      onChange={(event) => {
                        setSaveSysFlag(false)
                        setSelectedUserMass(Number(event.target.value))
                      }}
                    >
                      <FormControlLabel
                        value={massValue.massValue1}
                        control={<Radio />}
                        label={`${massValue.massValue1}${projectData?.unitId === 2 ? ' lb' : ' kg'}`} />
                      <FormControlLabel
                        value={massValue.massValue2}
                        control={<Radio />}
                        label={`${massValue.massValue2}${projectData?.unitId === 2 ? ' lb' : ' kg'}`} />
                    </RadioGroup>
                  </FormControl>
                </Box>
              </Box>
            </AccordionDetails>
          </Accordion>
          <Accordion
            defaultExpanded={true}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography sx={{ width: '33%', flexShrink: 0, textTransform: 'uppercase' }}>
                {t(KeyTranslation.System_LegsAndSpans)}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box sx={{ display: 'flex', justifyContent: 'end' }}>
                <Button size='small' onClick={() => { void autoDesignForAll() }} variant="outlined">
                  {t(KeyTranslation.Intelligent_System_Design)}
                </Button>
              </Box>
              <Box sx={{ mt: 4 }}>
                {listCircles?.map((circle, legIndex) => {
                  const spanLength = circle.leg?.spans?.length
                  if (legIndex > 0) {
                    return (
                      <Accordion
                        className='Accordion-main'
                        key={legIndex}
                        expanded={legIndex === openAccordionIndex}
                        onChange={() => {
                          if (legIndex === openAccordionIndex) {
                            setOpenAccordionIndex(-1)
                          } else {
                            setOpenAccordionIndex(legIndex)
                          }
                        }}
                      >
                        <AccordionSummary sx={{ alignItems: 'center', display: 'flex', flexWrap: 'wrap' }}
                          expandIcon={<ExpandMoreIcon />}
                        >
                          <Typography sx={{ width: '10%', flexShrink: 0 }}>
                            {`Leg${legIndex}`}
                          </Typography>
                          {circle.leg?.spans?.map((span, spanIndex) => {
                            return (
                              <Typography marginLeft={'5px'} key={spanIndex} sx={{ color: 'text.secondary' }}>
                                {`Sp${indexSpan + spanIndex + 1}`}
                              </Typography>
                            )
                          })}
                          <CloseSharpIcon onClick={() => { removeLeg(legIndex) }} sx={{ ml: 'auto' }} className='close-icon' />
                        </AccordionSummary>
                        <AccordionDetails>
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: 4,
                              mt: 2,
                              justifyContent: 'space-between'
                            }}
                          >
                            <Typography variant='body2' component={'span'}>
                              {t(KeyTranslation.System_Line_length)}
                            </Typography>
                            <Box display={'flex'}>
                              <TextField
                                sx={{ width: 200 }}
                                onChange={(event) => { updateLegLength(legIndex, Number(event.target.value)) }}
                                value={circle.leg?.length ?? ''}
                                inputProps={{ min: 0 }}
                                size="small" type="number"
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">{projectData?.unitId === 2 ? 'ft' : 'm'}</InputAdornment>
                                  )
                                }} />
                            </Box>
                          </Box>
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: 4,
                              mt: 2,
                              justifyContent: 'space-between'
                            }}
                          >
                            <Typography variant='body2' component={'span'}>
                              {t(KeyTranslation.System_Angle)}
                            </Typography>
                            <Box display={'flex'}>
                              <TextField
                                value={circle.leg?.angle ?? ''}
                                sx={{ width: 200 }}
                                size="small"
                                type='number'
                                onChange={(event) => {
                                  const inputValue = (event.target.value)
                                  updateLegRelativeAngle(legIndex, inputValue)
                                }}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">O</InputAdornment>
                                  )
                                }}
                              />
                            </Box>
                          </Box>
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: 4,
                              mt: 2,
                              justifyContent: 'space-between'
                            }}
                          >
                            <Typography variant='body2' component={'span'}>
                              {t(KeyTranslation.System_CoordinateX)}
                            </Typography>
                            <TextField
                              size="small"
                              type="number"
                              sx={{ width: 200 }}
                              value={circle.leg?.coordinateX}
                              InputProps={{
                                readOnly: true
                              }}
                            />
                          </Box>
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: 4,
                              mt: 2,
                              justifyContent: 'space-between'
                            }}
                          >
                            <Typography variant='body2' component={'span'}>
                              {t(KeyTranslation.System_CoordinateY)}
                            </Typography>
                            <TextField
                              size="small"
                              type="number"
                              sx={{ width: 200 }}
                              value={circle.leg?.coordinateY}
                              InputProps={{
                                readOnly: true
                              }}
                            />
                          </Box>
                          <Box sx={{ display: 'flex' }}>
                            <FormControl>
                              <RadioGroup
                                value={selectedFormControlValue}
                                onChange={(event) => { setSelectedFormControlValue(event.target.value) }}
                              >
                                <FormControlLabel
                                  value="Equal"
                                  onClick={() => {
                                    setEqualSpan({
                                      visible: true,
                                      data: circle
                                    })
                                  }}
                                  control={<Radio />}
                                  label="Equal Spans" />
                                <FormControlLabel
                                  value="Variable"
                                  control={<Radio />}
                                  label={t(KeyTranslation.System_Variable_Spans)} />
                                <FormControlLabel
                                  value="Implement"
                                  onClick={() => {
                                    if (!implementClicked) {
                                      setImplementClicked(true)
                                      void autoDesignForLeg(circle)
                                    }
                                  }}
                                  control={<Radio />}
                                  label="Implement Max-Span for Leg" />
                              </RadioGroup>
                            </FormControl>
                          </Box>
                          {circle.leg?.spans?.map((span, spanIndex) => {
                            indexSpan++
                            return (
                              <Box marginBottom={1} key={spanIndex} display="flex" alignItems="center" justifyContent="space-evenly">
                                <Typography width={30} variant="body2" component="span">
                                  {`Sp${indexSpan}`}
                                </Typography>
                                <Box display={'flex'}>
                                  <TextField
                                    size="small"
                                    type="number"
                                    value={span.length ?? ''}
                                    disabled={selectedFormControlValue !== 'Variable'}
                                    inputProps={{
                                      min: 0
                                    }}
                                    onChange={(e) => { updateSpanLength(legIndex, Number(e.target.value), spanIndex) }}
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="end">{projectData?.unitId === 2 ? 'ft' : 'm'}</InputAdornment>
                                      )
                                    }}
                                  />
                                </Box>
                                <IconButton
                                  size="small"
                                  onClick={() => { removeSpan(legIndex, spanIndex, spanLength ?? 0) }}
                                  disabled={selectedFormControlValue !== 'Variable'}
                                >
                                  <DeleteForeverOutlinedIcon />
                                </IconButton>
                              </Box>
                            )
                          })}
                          <Button size='small' sx={{ mt: 2, display: 'block', marginLeft: 'auto' }}
                            onClick={() => { addSpan(legIndex) }}
                            disabled={selectedFormControlValue !== 'Variable'} variant="outlined">{t(KeyTranslation.System_Add_Span)}</Button>
                        </AccordionDetails>
                      </Accordion>
                    )
                  } else {
                    return null
                  }
                })}
              </Box>
              <Button onClick={() => {
                if (listCircles !== undefined && listCircles.length > 0) {
                  addLeg(0, 0, listCircles[listCircles.length - 1])
                } else {
                  alert(t(KeyTranslation.PleaseChooseStartPointFirstByClickOnCanvas))
                }
              }} sx={{ mt: 2 }} variant="outlined" fullWidth>
                {t(KeyTranslation.System_Addleg)}
              </Button>
            </AccordionDetails>
          </Accordion>
        </Grid>
        {Boolean(equalSpan.visible) && (
          <EqualSpan open={equalSpan.visible}
            onClose={handleCloseModal} />
        )}
        {visibleReport && (
          <ContainerModelReport
            open={visibleReport}
            onClose={() => {
              setVisibleReport(false)
            }}
          />
        )}
        {outputModal && (
          <OutputDiagram
            open={outputModal}
            onClose={() => { setOutputModal(false) }}
            systemCalculateResult={systemCalculateResult}
            applicationLocationId={systemData.applicationLocationId}
            projectData={projectData}
            locationMetrics={locationMetrics}
            raise={raise}
            spanResultIndex={spanResultIndex}
            srlLengthAtFall={srlLengthAtFall}
            calMessage={calMessage.standing}
            warning={warning.standing}
          />
        )}
      </Grid>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  )
}
