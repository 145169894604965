/* tslint:disable */
/* eslint-disable */
/**
 * ESDS.Web.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { Int32ApiResponse } from '../models';
// @ts-ignore
import { NoteDto } from '../models';
// @ts-ignore
import { NoteDtoIEnumerableApiResponse } from '../models';
/**
 * NoteApi - axios parameter creator
 * @export
 */
export const NoteApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV2NoteGetByProjectIdGet: async (projectId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/Note/GetByProjectId`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (projectId !== undefined) {
                localVarQueryParameter['projectId'] = projectId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV2NoteIdDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV2NoteIdDelete', 'id', id)
            const localVarPath = `/api/v2/Note/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {NoteDto} [noteDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV2NotePost: async (noteDto?: NoteDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/Note`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(noteDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {NoteDto} [noteDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV2NotePut: async (noteDto?: NoteDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/Note`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(noteDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [projectId] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [search] 
         * @param {string} [sortField] 
         * @param {boolean} [isDescending] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV2NoteSearchNoteByPagingGet: async (projectId?: number, page?: number, pageSize?: number, search?: string, sortField?: string, isDescending?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/Note/SearchNoteByPaging`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (projectId !== undefined) {
                localVarQueryParameter['ProjectId'] = projectId;
            }

            if (page !== undefined) {
                localVarQueryParameter['Page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (search !== undefined) {
                localVarQueryParameter['Search'] = search;
            }

            if (sortField !== undefined) {
                localVarQueryParameter['SortField'] = sortField;
            }

            if (isDescending !== undefined) {
                localVarQueryParameter['IsDescending'] = isDescending;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NoteApi - functional programming interface
 * @export
 */
export const NoteApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NoteApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV2NoteGetByProjectIdGet(projectId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NoteDtoIEnumerableApiResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV2NoteGetByProjectIdGet(projectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV2NoteIdDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV2NoteIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {NoteDto} [noteDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV2NotePost(noteDto?: NoteDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Int32ApiResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV2NotePost(noteDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {NoteDto} [noteDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV2NotePut(noteDto?: NoteDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV2NotePut(noteDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [projectId] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [search] 
         * @param {string} [sortField] 
         * @param {boolean} [isDescending] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV2NoteSearchNoteByPagingGet(projectId?: number, page?: number, pageSize?: number, search?: string, sortField?: string, isDescending?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NoteDtoIEnumerableApiResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV2NoteSearchNoteByPagingGet(projectId, page, pageSize, search, sortField, isDescending, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * NoteApi - factory interface
 * @export
 */
export const NoteApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NoteApiFp(configuration)
    return {
        /**
         * 
         * @param {number} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV2NoteGetByProjectIdGet(projectId?: number, options?: any): AxiosPromise<NoteDtoIEnumerableApiResponse> {
            return localVarFp.apiV2NoteGetByProjectIdGet(projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV2NoteIdDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiV2NoteIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {NoteDto} [noteDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV2NotePost(noteDto?: NoteDto, options?: any): AxiosPromise<Int32ApiResponse> {
            return localVarFp.apiV2NotePost(noteDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {NoteDto} [noteDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV2NotePut(noteDto?: NoteDto, options?: any): AxiosPromise<void> {
            return localVarFp.apiV2NotePut(noteDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [projectId] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [search] 
         * @param {string} [sortField] 
         * @param {boolean} [isDescending] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV2NoteSearchNoteByPagingGet(projectId?: number, page?: number, pageSize?: number, search?: string, sortField?: string, isDescending?: boolean, options?: any): AxiosPromise<NoteDtoIEnumerableApiResponse> {
            return localVarFp.apiV2NoteSearchNoteByPagingGet(projectId, page, pageSize, search, sortField, isDescending, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NoteApi - object-oriented interface
 * @export
 * @class NoteApi
 * @extends {BaseAPI}
 */
export class NoteApi extends BaseAPI {
    /**
     * 
     * @param {number} [projectId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoteApi
     */
    public apiV2NoteGetByProjectIdGet(projectId?: number, options?: AxiosRequestConfig) {
        return NoteApiFp(this.configuration).apiV2NoteGetByProjectIdGet(projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoteApi
     */
    public apiV2NoteIdDelete(id: number, options?: AxiosRequestConfig) {
        return NoteApiFp(this.configuration).apiV2NoteIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NoteDto} [noteDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoteApi
     */
    public apiV2NotePost(noteDto?: NoteDto, options?: AxiosRequestConfig) {
        return NoteApiFp(this.configuration).apiV2NotePost(noteDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NoteDto} [noteDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoteApi
     */
    public apiV2NotePut(noteDto?: NoteDto, options?: AxiosRequestConfig) {
        return NoteApiFp(this.configuration).apiV2NotePut(noteDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [projectId] 
     * @param {number} [page] 
     * @param {number} [pageSize] 
     * @param {string} [search] 
     * @param {string} [sortField] 
     * @param {boolean} [isDescending] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoteApi
     */
    public apiV2NoteSearchNoteByPagingGet(projectId?: number, page?: number, pageSize?: number, search?: string, sortField?: string, isDescending?: boolean, options?: AxiosRequestConfig) {
        return NoteApiFp(this.configuration).apiV2NoteSearchNoteByPagingGet(projectId, page, pageSize, search, sortField, isDescending, options).then((request) => request(this.axios, this.basePath));
    }
}
