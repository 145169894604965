import React, { useEffect, useRef, useState } from 'react'
import { Box, Typography, Button, Modal } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import { OAuthApi, UserApi } from '../../common/services'
import { omit } from 'lodash'
import localStorage from '../../common/localStorage'
import { ModalContent } from '../../common/components'

export default function Login (): JSX.Element {
  const [eulaModalOpen, setEulaModalOpen] = useState(false)
  const params = useParams()
  const navigate = useNavigate()
  const [hasScrolledToBottomOnce, setHasScrolledToBottomOnce] = useState(false)
  const [isLoading, setIsloading] = useState(false)

  const scrollBoxRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    if (params?.token != null) {
      new OAuthApi().apiV2OAuthGetUserInfoBySecurityCodeSecurityCodePost(params.token).then(({ data }) => {
        const user = omit(data.data, [
          'actions',
          'childCompanies',
          'modules',
          'services'
        ])
        localStorage.setItem('LOGIN', user)

        if ((data?.data?.termAndConditionAccepted) === false) {
          setEulaModalOpen(true)
        } else {
          navigate('/')
        }
      }).catch(() => {
      })
    }
  }, [params, navigate])

  const handleAcceptEula = async (): Promise<void> => {
    const values = {
      version: '1.0.0',
      languageAccepted: 'English'
    }
    try {
      setIsloading(true)
      const response = await new UserApi().apiV2UserTermAndConditionsPost(values)
      if (response.data.data === true) {
        setEulaModalOpen(false)
        navigate('/')
      } else {
        setEulaModalOpen(false)
        window.location.href = process.env.REACT_APP_LOGIN_URL ?? ''
      }
    } catch (ex: any) {
      console.log(ex)
    } finally {
      setIsloading(false)
    }
  }
  const handleClose = (): void => {
    setEulaModalOpen(false)
    window.location.href = process.env.REACT_APP_LOGIN_URL ?? ''
  }
  const handleScroll = (): void => {
    const scrollBox = scrollBoxRef.current
    if (scrollBox != null) {
      const isBottom = scrollBox.scrollTop + scrollBox.clientHeight >= scrollBox.scrollHeight - 5
      if (isBottom && !hasScrolledToBottomOnce) {
        setHasScrolledToBottomOnce(true)
      }
    }
  }
  return (
    <Box sx={{ width: '100vw', height: '100vh' }} display="flex" justifyContent="center" alignItems="center">
      <Box display="flex" flexDirection="column" alignItems="center">
        <img src={require('../../assets/images/3M_Active Safety_RGB_H43.png')} alt='logo' height={36} />
        <Typography variant='h3' mt={4}>Authenticating...</Typography>
      </Box>

      <Modal
        open={eulaModalOpen}
        onClose={() => { handleClose() }}
      >
        <ModalContent onClose={() => { handleClose() }} title={'Terms of Service and License for 3M Engineered Systems Design Service'} width={1280}>
          <Box
            sx={{ mt: 4, maxHeight: '800px', overflowY: 'auto' }}
            onScroll={handleScroll}
            ref={scrollBoxRef}
          >
            <Typography>
              This Agreement is 3M’s Terms of Service and License that governs its relationship among you, the principal on whose behalf you are authorized to act (the “Principal,” with you and the Principal collectively being the “User”), and “3M” (through 3M Company or its Affiliate(s) in the local country in which online services are rendered to the User) via the Engineered Systems Design (ESD) Service (the “Service”).
              <br />
              <br />
              By accessing or otherwise using any part of the Service, you agree to and contract with 3M under the terms and conditions of this Agreement. If you do not agree to the terms and conditions of this Agreement, you are prohibited from any use of the Service.
              <br />
              <br />
              This Agreement also includes PRIVACY NOTICE FOR 3M ENGINEERED SYSTEMS DESIGN SERVICE below.
              <br />
              <br />
              Any use of the Service constitutes acceptance of this Agreement.
              <br />
              <br />
              <b>1.  Definitions</b>
              <br />
              <br />
              1.1.  “Service” includes (a) any of the following that accompanies this Agreement including designs, text, graphics, images, logos, video, audio files, information, documentation, instructions, computer code (in any form including source or object form), program files, executables, dynamic link libraries, hardware or software keys; (b) data or content that are provided, accessed, utilized, or generated by the Service; (c) all revisions, updates, and upgrades to the Service; and (d) any embodiment of (a)-(c) that is resident in a Licensor-supplied system (including 3M personal protective equipment) or accessed via a browser application running on any computing system that (i) communicates with or accesses the Service or (ii) is provided, transferred, or otherwise sold by 3M, where “Licensor- supplied” means supplied by 3M or its suppliers.
              <br />
              <br />
              1.2.  “Affiliate” means any: (a) individual who or “Entity” (i.e., any corporation, firm, partnership, proprietorship, or other form of business organization) that, in whatever country organized or resident, directly or indirectly, is controlled by, or is under common control with, or controls a party; or (b) Entity in which a party or any Entity in which any individual or Entity recited in subparagraph (a) directly or indirectly has at least a fifty percent ownership or voting rights interest (whether through stock ownership, stock power, voting proxy, or otherwise), or has the maximum ownership interest it is permitted to have in the country where such Entity exists.
              <br />
              <br />
              1.3.   “Authorized User” means an employee, contractor, or authorized agent of User or its Affiliates who is
              authorized to access or use the Service in connection with the conduct of User’s use as permitted hereunder.
              <br />
              <br />
              <b>2.  Term and Termination
              </b>
              <br />
              <br />
              1.  The Effective Date of this Agreement is the earlier date of User’s use of or access to the Service and the term of this Agreement shall continue until it is terminated. User may terminate this Agreement at any time by (a) ceasing use of the service, and (b) desisting from any future use of the Service.  User may also terminate this Agreement by (a) submitting a request to be removed from the Service via email to  privacycompliance@mmm.com and (b) receiving express written acknowledgment that 3M has removed User from the Service.  In addition to its other remedies at law and in equity, 3M may terminate this Agreement and the license grants set forth herein at any time if: (i) 3M provides thirty (30) days written notice to the User (ii) User is in material breach of any term or condition hereof or any other agreement with 3M; or (iii) if User fails to make timely payment of any service, license, or support fees due to 3M and such default is not cured within thirty (30) business days of User’s receipt of written notice of such default. Upon termination of this Agreement, all license rights granted to User shall immediately terminate and User must cease all use of the Service.
              <br />
              <br />
              2.  In the event of expiration or termination, User shall immediately cease all access to the Service and desist from any future access to the Service., within thirty (30) days of such termination or expiration, return, delete, or destroy any of the Software in User’s possession. User is solely responsible for downloading prior to the expiration or termination date all appropriate data  generated by way of User’s use of the Service. On or after the expiration or termination date, 3M may delete all data it holds on behalf of the User without liability of any kind to User.
              <br />
              <br />
              <b> 3.  Permitted Use and License</b>
              <br />
              <br />
              3M hereby grants to User a worldwide, limited, non-exclusive, and non-transferable license to access and use the Service in accordance with this Agreement. The Service is only licensed for use with any authentication credentials provided by 3M to the User. User agrees not to use, or permit others to use the Service for any purposes beyond the scope of this Agreement.  Without limiting the foregoing, User shall not, nor permit others to: (a) resell, sublicense, lease, time-share or otherwise make the Service available to any third party, including but not limited to for third-party training, commercial time-sharing, application service provider or service bureau use; (b) transfer or copy any software component (e.g., functional software) thereof; (c) modify, adapt or make derivative works of the Service, or reverse engineer the software underlying the Service (provided that the foregoing shall not be construed to prohibit User from configuring the Service to the extent permitted via the Service’s standard user interface provided to User); (d) remove, obscure, or alter 3M’s proprietary notices, trademarks, or other proprietary rights notices embodied on or provided via the Service or any accompanying documentation; (e) use the Service in contravention of any federal, state, local, foreign or other applicable law, or rules or regulations (including, but not limited to, health and/or safety regulations) of regulatory or administrative organizations; (f) introduce into any system hosting the Service any virus or other code or routine intended to disrupt or damage the Service or its content, or collect information about the Service or the system(s) hosting the Service;
              (g) use the Service to send or store infringing or unlawful material; (h) access the Service for the purpose of building, or assisting others to build, a competitive product or service or copying its features, content or user interface, including without limitation by screen scraping; (i) access or use the Service by means of any interfacing program, script, automated program, electronic agent or “bot” or; (j) use the Service in association with any article of personal protective equipment that is not approved or otherwise authorized for use by 3M with the Service by 3M.
              <br />
              <br />
              3.1.  Unless expressly provided herein, 3M retains all rights to all intellectual property embodied in or related to the products and services hereunder, including software that might be executed to deliver the Service, except that any User-provided data is owned by User and User grants 3M certain rights to use the User-provided data as described in Section 9 and as otherwise specified in this Agreement. No ownership rights in any intellectual property are transferred to User under this Agreement. User disclaims and waives all right, title, and interest in any aspect of the Service other than the license rights expressly set forth herein. To the extent that User is ever deemed to have any right, title, or interest in any aspect of the Service, or in any modification or derivative work thereof, User agrees to assign, and hereby assigns the same to 3M in accordance with their respective ownership interests, as determined by 3M. User shall not sublicense, rent, copy, modify, create derivative works of, reverse engineer, decompile, disassemble or otherwise attempt to gain access to the source code for software associated with the Service, or work with any individual or company that seeks to do any such activity.
              <br />
              <br />
              3.2. Portions of software underlying the Service may incorporate one or more open-source software components. By using the Service, User agrees to be bound to the terms of any third-party licenses for open-source software component(s) included in the software underlying the Service.
              <br />
              <br />
                <b>4.  Credentials</b>
              <br />
              <br />
              4.1. 3M may issue unique user IDs and passwords to enable authorized parties acting on behalf of User to access and/or use the Service. Each user ID and password combination is unique, confidential, and intended for use only by a single authorized party to whom it is assigned. User may request 3M to modify the list of authorized parties at various times to substitute for authorized parties, add authorized parties, or delete authorized parties.  3M may approve or deny such requests, or request additional information in processing the request, at 3M’s sole discretion.
              <br />
              <br />
               <b>5.  Support</b>
              <br />
              <br />
              5.1. 3M may, at its sole discretion, use commercially reasonable efforts to provide User support for the Service.  However, unless specifically provided for in a separate agreement between 3M and User, in no event shall 3M be obligated to provide support for the Service to the User. User expressly acknowledges that 3M does not guarantee a specific response time to support requests for the Service.
              <br />
              <br />
              5.2. 3M shall have no obligation to provide support relating to: (a) products not sold, provided, or approved by 3M; (b) third-party products not embedded in the software underlying the Service, i.e., products sold under a brand name not owned by 3M (e.g., hardware, devices, other equipment and certain software “add-ons”); (c) any version of the Service that has been retired by 3M, except upon 3M’s election; (d) any Service that is neither the most current nor the immediately preceding version of the Service, except upon 3M’s election; (e) any software or service that is not specified or not referenced to in this Agreement.
              <br />
              <br />
               <b>6.  Warranty</b>
              <br />
              <br />
              6.1. USE OF THE SERCIE IS AT USER’S SOLE RISK. THE SERVICE IS PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS.
              <br />
              <br />
              6.2. 3M AND ITS OFFICERS, DIRECTORS, AGENTS, EMPLOYEES, SUPPLIERS, LICENSORS, AND SUBCONTRACTORS DISCLAIM AND USER WAIVES ALL OTHER WARRANTIES OR CONDITIONS, EXPRESS OR IMPLIED, CONCERNING THE SERVICE, DOCUMENTATION, OR OTHER RELATED INFORMATION, INCLUDING BUT NOT LIMITED TO THE IMPLIED WARRANTIES OR CONDITIONS OF MERCHANTABILITY, SATISFACTORY QUALITY, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT, FREEDOM FROM ERRORS OR DEFECTS, AND ANY WARRANTIES OR CONDITIONS OF SECURITY AGAINST DATA BREACHES OR INTRUSIONS. 3M, ITS SUPPLIERS, LICENSORS, AND SUBCONTRACTORS DO NOT WARRANT THAT THE SERVICE OR DATA WILL MEET USER’S REQUIREMENTS, THAT OPERATION OF THE SERVICEE OR AVAILABILITY OF DATA WILL BE UNINTERRUPTED, OR THAT THE SERVICE OR DATA IS WITHOUT ERROR. NEITHER 3M NOR ITS OFFICERS, DIRECTORS, AGENTS, EMPLOYEES, SUPPLIERS, LICENSORS, AND SUBCONTRACTORS WILL BE RESPONSIBLE FOR THE ACCURACY OR USE OF DATA ACCESSED BY OR OUTPUT BY THE SERVICE. THE ABOVE-STATED LIMITED WARRANTY IS VOID WITH RESPECT TO ANY UNAUTHORIZED USE OF THE SERVICE, USE OF THE SOFTWARE OTHER THAN IN ACCORDANCE WITH THE WRITTEN INSTRUCTIONS AND DOCUMENTATION OF 3M AND ITS OFFICERS, DIRECTORS, AGENTS, EMPLOYEES, SUPPLIERS, LICENSORS, AND SUBCONTRACTORS, USE OF THE SERVICE WITH PRODUCTS AND SERVICES NOT PROVIDED BY 3M OR ITS OFFICERS, DIRECTORS, AGENTS, EMPLOYEES, SUPPLIERS, LICENSORS, AND SUBCONTRACTORS, AND AS TO A SERVICE THAT HAS BEEN DAMAGED OR MODIFIED OTHER THAN BY 3M OR ITS OFFICERS, DIRECTORS, AGENTS, EMPLOYEES, SUPPLIERS, LICENSORS, AND SUBCONTRACTORS.
              <br />
              <br />
              <b>7.  Limitation of Liability</b>
              <br />
              <br />
              IN NO EVENT WILL 3M OR ITS OFFICERS, DIRECTORS, AGENTS, EMPLOYEES, SUPPLIERS, LICENSORS, AND SUBCONTRACTORS BE LIABLE (I) FOR ANY SPECIAL, INCIDENTAL, INDIRECT, CONSEQUENTIAL OR EXEMPLARY DAMAGES OF ANY KIND, INCLUDING BUT NOT LIMITED TO ANY LOST PROFITS OR LOST SAVINGS, HOWEVER CAUSED, WHETHER FOR BREACH OR REPUDIATION OF CONTRACT, TORT, BREACH OF WARRANTY, NEGLIGENCE, OR OTHERWISE, WHETHER OR NOT 3M OR ITS OFFICERS, DIRECTORS, AGENTS, EMPLOYEES, SUPPLIERS, LICENSORS, AND SUBCONTRACTORS WERE ADVISED OF THE POSSIBILITY OF SUCH LOSS OR DAMAGES; (II) FOR LOSS OF, DAMAGE TO, OR BREACH OF SERVICE, RECORDS OR DATA; (III) FOR THE COST OF PROCUREMENT OF SUBSTITUTE GOODS AND SERVICES; (IV) FOR THIRD-PARTY CLAIMS AGAINST USER FOR LOSSES OR DAMAGES (V) BUSINESS INTERRUPTION; OR (VI) ANY CLAIMS ARISING FROM USER’S USE OF THIRD PARTY SUPPLIES OR EQUIPMENT. NOTWITHSTANDING ANY OTHER PROVISION OF THIS AGREEMENT, THE TOTAL LIABILITY TO USER OF 3M AND ITS OFFICERS, DIRECTORS, AGENTS, EMPLOYEES, SUPPLIERS, LICENSORS, AND SUBCONTRACTORS IS LIMITED TO ONE YEAR OF PAYMENTS MADE BY USER TO 3M AND ITS SUPPLIERS, LICENSORS, AND SUBCONTRACTORS FOR THE PRODUCT OR SERVICE WHICH WAS THE SUBJECT OF THE CLAIM.
              <br />
              <br />
              <b>8.  User Responsibilities</b>
              <br />
              <br />
              Although 3M makes the Service available for User’s use, User understands and acknowledges that it is solely responsible for its use of the Service including, but not limited to, complying with all user instructions, safe workplaces practices, and the monitoring and verifying the input to and output from the Service. The Service is not a replacement for visual and functional inspections of personal protective equipment. <b>
                THE SERVICE IS INTENDED TO SUPPLEMENT, NOT REPLACE, APPROPRIATE WORKPLACE, PRIVACY, HEALTH, SAFETY AND ENVIRONMENTAL POLICIES AND PRACTICES. THE SERVICE IS INTENDED TO SUPPLEMENT, NOT REPLACE, ANY USER INSTRUCTIONS, EQUIPMENT USAGE GUIDELINES, OR ANY OTHER DOCUMENTATION PROVIDED BY ANY EQUIPMENT MANUFACTURER, DISTRIBUTOR, OR MAINTENANCE PROVIDER.  USER IS SOLELY RESPONSIBLE FOR COMPLIANCE WITH ALL WORKPLACE, PRIVACY, HEALTH, SAFETY, ENVIRONMENTAL AND OTHER APPLICABLE LAWS AND REGULATIONS. USER WILL INDEMNIFY, HOLD HARMLESS, AND DEFEND 3M AND ITS OFFICERS, DIRECTORS, AGENTS, AND EMPLOYEES FROM AND AGAINST ALL CLAIMS, LOSSES, DAMAGES, PENALTIES, FINES, LIABILITIES, COSTS, AND EXPENSES, INCLUDING ATTORNEY’S FEES, ARISING OUT OF OR IN CONNECTION WITH USER’S USE OF THE SERVICE OR USER’S FAILURE TO COMPLY WITH SUCH LAWS AND REGULATIONS.
              </b>
              <br />
              <br />
              <b>9.  User Data and 3M’s Use of User Data</b>
              <br />
              <br />
              9.1.  User acknowledges, agrees, and understands that, in connection with 3M providing User with access to and
              use of the Service, information resulting from User’s use of the Service may be transmitted to 3M. User further
              acknowledges, agrees, and understands that such information will relate to User, authorized parties and the devices
              utilized by User and authorized parties, and that such information will be stored and maintained by 3M, its
              Affiliates, subcontractors and/or its agents (such information is collectively “User Data”). User is solely
              responsible for the timeliness and accuracy of all User Data submitted via the Service and represents that it has all
              necessary authority for the disclosure of User Data to 3M.
              <br />
              <br />
              9.2.  3M and its Affiliates, subcontractors, and agents may monitor, access, collect, and use, but only as permitted
              by law, User Data in order to (a) operate, support, and enhance the Service for the User, (b) monitor compliance
              with the Agreement, (c) inform the User of products, support, and offerings, (d) inform and/or guide decision-making
              in the development of future-developed safety products, and (e) as required by applicable laws.
              <br />
              <br />
              9.3.  3M may de-identify User Data using standard industry practices. Information that has been de-identified
              such that it does not identify User or Authorized Users shall not be User Data, and 3M may use or disclose it for any
              purpose, including to improve the Software, create new features, to conduct research, sell personal protective
              equipment or services, and develop new products or services, including reports based on analytics associated with
              the Software. 3M may also aggregate User Data with other data and may use that aggregated User Data to perform
              services for or provide products to third parties, provided that no such data would enable those third parties to
              identify Authorized Users or User, absent the consent of User. 3M’s use and retention of de-identified User Data for
              uses and purposes set forth herein may extend beyond the term of this Agreement.
              <br />
              <br />

              9.4. User acknowledges, agrees and understands that 3M may store User Data on servers provided by third
              parties in the US and countries other than the US. User is responsible for complying with all applicable laws,
              including local and other regulations, pertaining to personal information, such as data from or relating to its
              workers. User acknowledges, agrees and understands the privacy notice attached hereto PRIVACY NOTICE FOR
              3M ENGINEERED SYSTEMS DESIGN SERVICE or simply <b>Privacy Notice</b> and User is responsible for providing
              individuals (e.g., authorized parties) with Privacy Notice and any additional applicable privacy notices and obtaining
              any consents required by applicable laws for the collection, use, disclosure, and processing of personal information in
              connection with User’s use of the Service.
              <br />
              <br />
              9.5.  Upon 3M’s confirmation of an incident in which there is unauthorized access to User Data (“Security
              Incident”), 3M will (i) notify User promptly after 3M becomes aware of the confirmed Security Incident and (ii) take
              reasonable steps to mitigate the effects and minimize any damage resulting from the Security Incident.
              <br />
              <br />
              9.6.  User Data may be stored in personal protective equipment and/or mobile applications installed on mobile
              devices authorized for accessing the Service. User is solely responsible for safeguarding the personal protective
              equipment, the mobile device, and their appropriate use.
              <br />
              <br />
              <b>10.  Feedback Automatically Becomes 3M’s Property</b>
              <br />
              <br />
              Any feedback or suggestions provided by User to 3M regarding products including the Service (hereinafter,
              “Feedback”) shall be the property of 3M and User hereby assigns to 3M all right, title and interest worldwide in and to
              such Feedback and the related intellectual property rights. User acknowledges and understands that, in connection
              with certain products and services, certain information regarding User’s use of the Service is transmitted to 3M.
              Such information may be collected and used by 3M to provide support services to User, and for research and
              marketing purposes. For the avoidance of doubt, 3M may use any Feedback, including, but not limited to,
              suggestions, product experiences, and product outcomes provided or realized by User to improve or market any
              product including but not limited to the Service, create new features, to conduct research, and develop new products
              or services, including reports based on analytics associated with usage of the Service.
              <br />
              <br />
              <b>11.  General</b>
              <br />
              <br />
              11.1.  Governing Law, Limitation on Action and Attorneys’ Fees. Unless otherwise mandated by law, this
              Agreement is governed exclusively by the laws of the State of Minnesota, USA, excluding its conflict of laws rules,
              and specifically excluding the United Nations Convention on Contracts for the International Sale of Goods. The
              parties consent to personal jurisdiction and venue in the state and federal courts sitting in Ramsey County in the State
              of Minnesota, USA, and agree that all disputes arising from this Agreement shall be venued exclusively in such courts.
              The parties also agree that, regardless of any statute or law to the contrary, any claim or cause of action arising out
              of or related to this Agreement must be brought within one (1) year after such claim or cause of action arose or be
              forever barred.
              <br />
              <br />
              11.2.  Export Restrictions. User agrees that it will not export or re-export (directly or indirectly) any products or
              documentation or other technical data therefore, in whole or in part, without complying with the U.S. Export
              Administration Act of 1979 and the regulations thereunder. User shall indemnify, defend (with counsel approved in
              writing in advance by 3M) and hold 3M harmless against any claims for cost, damage, expense or liability arising out
              of or in connection with these restrictions by User.
              <br />
              <br />
              11.3.  Assignment. User may not assign, voluntarily, by operation or law, in connection with a change in control,
              merger, or otherwise, this Agreement or any licenses granted or obligations set forth in these Terms and Conditions
              to any third party without prior written consent of 3M.
              <br />
              <br />
              11.4.  Severability. If any provision of this Agreement is held invalid, illegal, unenforceable, or in conflict with any
              law having jurisdiction over this Agreement, the validity, legality, and enforceability of the remaining portions or
              provisions hereof will not be affected or impaired.
              <br />
              <br />
              11.5.  Force Majeure. Neither Party will be responsible for any failure to perform due to causes beyond its
              reasonable control (each a “Force Majeure”), including, but not limited to, acts of God, war, rioting, insurrection
              criminal acts, embargoes, acts of civil or military authorities, denial of or delays in processing of export license
              applications, fire, floods, earthquakes, accidents, strikes, or fuel crises, terrorist actions, cybersecurity incidents,
              SaaS or cloud-native app downtimes, or widespread internet or electrical outages, provided that such Party gives
              prompt written notice thereof to the other Party.
              <br />
              <br />
              11.6.  Waiver. No waiver will be implied from conduct or failure to enforce rights, and no waiver will be effective
              unless in writing signed on behalf of the party against whom the waiver is asserted. The exercise of any right or
              remedy provided in this Agreement will be without prejudice to the right to exercise any other right or remedy
              provided by law or equity, except as expressly limited by this Agreement.
              <br />
              <br />
              11.7.  Compliance with Laws. User is solely responsible for determining whether its use of the Software complies
              with applicable laws and regulations, including but not limited to those governing the collection, storage and use of
              personal information. User is solely responsible for establishing and maintaining policies, procedures, and third-party
              agreements relating to such information (including relating to collection, storage and use) as required by law.
              <br />
              <br />
              11.8.  Prior Agreement. This Agreement supersedes and replaces any and all prior agreements for the Service
              previously executed by the User.
              <br />
              <br />
              11.9.  Amendments. 3M will notify User of any material changes to the Agreement. User’s continued use of the
              Service following changes to the Agreement constitutes User’s acceptance of any changed terms.
              <br />
              <br />
              11.10.  Notices. Any notice required or permitted by this Agreement shall be in writing to (1) 3mfallprotection@mmm.com for 3M or (2) a mailing address or email address of User or Principal.
              <br />
              <br />
              <b style={{ fontSize: 20 }}>Region-Specific Terms of Service for 3M Engineered Systems Design Service</b>
              <br />
              <br />
              In addition to the Terms of Service and License for 3M Engineered Systems Design Service set forth above,
              User further agrees to and contracts with 3M under the following Region-Specific Terms of Service for the
              Service for any country or region designated below from which the User accesses or uses the Service.
              <br />
              <br />
              <b>Canada</b>
              <br />
              <br />
              <b>User Data and 3M’s Use of User Data</b>
              <br />
              <br />
              User and 3M hereby acknowledge and agree:
              <br />
              <br />
              (a) that User has obtained the relevant consent necessary for the collection, use, disclosure, and transmission of
              personal information (defined as any piece of information, either factual or subjective, about an identifiable individual)
              for storage outside of Canada in connection with User’s use of the Software, in accordance with PIPEDA (Personal
              Information Protection and Electronic Documents Act) and any other applicable provincial legislation concerning the
              protection of personal information;
              <br />
              <br />
              (b) that all personal information exchanged between User and 3M will be used solely for the purposes described in
              this Agreement and for no other purpose;
              <br />
              <br />
              (c) not to disclose any personal information other than what is necessary to fulfill the purpose of the Agreement or
              as required by law;
              <br />
              <br />
              (d) to use appropriate security measures to protect all personal information exchanged between User and 3M;
              <br />
              <br />
              (e) to return or securely dispose of the transferred information once it is no longer needed for the purpose specified
              herein or after the termination of the Agreement; and
              <br />
              <br />
              (f) to cooperate with one another by notifying each other of all access requests, rectification requests or consent
              revocations received relative to personal information held by User or 3M as a result of the exchange of information
              between the parties.
              <br />
              <br />
              (g) User shall indemnify 3M and its directors, officers, agents and employees from and against any direct damages
              which may incur in connection with a breach of the clauses (a) to (f) or a breach of PIPEDA or other related provincial
              legislation concerning the protection of personal information by 3M, its officers, employees, agents and
              subcontractors.
              <br />
              <br />
              <b>Australia and New Zealand</b>
              <br />
              <br />
              The benefits of any warranty in this Agreement are in addition to other rights and remedies that User may have under
              a law in relation to the goods or services. Except as provided for in any warranty in this Agreement, and except to
              the extent that such liability is not able to be excluded by law, all other liability of 3M, whether arising from negligence
              or otherwise is expressly excluded. For the avoidance of doubt, except where required by the Australian Consumer
              Law or the Consumer Guarantees Act 1993 (NZ), 3M will not be liable for any indirect, special, incidental or
              consequential loss.
              <br />
              <br />
              <b style={{ fontSize: 20 }}>PRIVACY NOTICE FOR 3M ENGINEERED SYSTEMS DESIGN SERVICE</b>
              <br />
              <br />
              Please read this privacy notice (“Privacy Notice”) to learn more about how 3M Company (“3M” or “We”) uses and
              discloses information collected though the 3M Engineered Systems Design Service (the “Service”), including
              personal information.
              <br />
              <br />
              By using the Service, you consent to the collection, use, and sharing of information as described in this Privacy
              Notice. This Privacy Notice is incorporated into and subject to our End User License Agreement.
              <br />
              <br />
              This Privacy Notice is complementary and shall be read in conjunction with 3Ms Global Privacy Policy.
              <br />
              <br />
              <b>1.  What is the purpose of this Service?</b>
              <ul>
                <li> - Access 3M product information in a cloud-based software interface</li>
                <li> - Accurately calculate fall distances for 3M HLL systems</li>
                <li> - Produce full technical reports for engineered system designs</li>
              </ul>
              <br />
              <br />
              <b>2.  What information do we collect?</b>
              <br />
              <br />
              The Service collects the following type of information:
              <br />
              <br />
              Information about the user and their organization, such as:
              <br />
              <br />
              <ul>
                <li> - User email, first name, last name, language preference and username</li>
                <li> - User organization information, such as Company Name, address and contact information</li>
                <li> - Project information, including measurements, environment characteristics and other data required to
                  perform system calculations and recommendations</li>
                <li> - Web browser version and configuration settings</li>
                <li> - Other diagnostics data to measure technical diagnostics related to the usage of the Service</li>
              </ul>
              <br />
              <br />
              The Service collects this data automatically when you access and use it. We may also collect information from
              you directly outside of the Service if you provide it to us, for example, based on an inquiry you make associated
              with the App.
              <br />
              <br />
              <b>
                3. How does 3M use collected information?
              </b>
              <br />
              <br />
              We use collected information:
              <br />
              <br />
              <ul>
                <li> - to provide you with the requested services related to usage of Service.</li>
                <li> - to improve and develop current and future products and services of 3M.</li>
                <li> - to enforce compliance with our terms of use and/or 3M policies, to comply with law, regulation,
                  court order, or other legal process.</li>
              </ul>
              <br />
              <br />
              <b>4. Will 3M disclose any of the information it collected?</b>
              <br />
              <br />
              3M may share collected information for the purposes described above as follows:
              <br />
              <br />
              <ul>
                <li>- with other 3M entities, including those in different countries. When we do so, these other 3M
                  entities will use your information in a manner consistent with this Privacy notice, 3M’s Global
                    Privacy Policy and all applicable privacy and data protection laws.</li>
                <li>- with third parties we hire to perform support services for us and act on our behalf. These third
                  parties are required to treat your information in compliance with all applicable privacy and data
                  protection laws.</li>
                <li>- With third parties , to the extent that 3M has a good faith belief that such disclosure is necessary
                  to (a) comply with law, regulatory requirement, judicial proceeding, court order, or other legal
                  process served on us, (b) protect the safety, rights, or property of 3M, you, others or public welfare,
                  or (c) detect, prevent, or respond to fraud, intellectual property infringement, violations of our End
                    User License Agreement.</li>
                <li>- with third parties in connection with the sale, purchase, merger, reorganization, liquidation or
                  dissolution of 3M or a 3M business unit, or under similar circumstances. If such an event occurs,
                  we will take appropriate steps to protect your personal information.</li>
                <li>- with your permisssion or at your request.</li>
              </ul>
              <br />
              <br />
              <b>5. How does 3M secure my information?</b>
              <br />
              <br />
              3M takes steps to protect information collected through the Service. Unfortunately, the Internet and any technical
              system cannot be guaranteed to be 100% secure or error-free, and we cannot ensure or warrant the security of any
              information you provide to us.
              <br />
              <br />
              <b>6. Where is this information processed?</b>
              <br />
              <br />
              All information collected by the Service will be processed in and subject to the laws of the United States, which, if
              you are not located in the United States, may not provide the same level of protection for your information as your
              home country. In addition, we may transfer your information outside the United States to our affiliates, and
              service providers located in other countries. By using the Service and providing us your information, you
              consent to such transfer of your information to, and processing of your information in, the United States and these
              other countries.
              <br />
              <br />
              <b>7. What choices do I have?</b>
              <br />
              <br />
              a.  You may choose not to access the Service. You may choose not to provide certain information
              requested by the Service. Please keep in mind that withholding this information may prevent the
              Service from providing full functionality, as described above.
              <br />
              <br />
              <b>
                8. Data Retention and Deletion
              </b>
              <br />
              <br />
              We will retain your information for our legitimate business purposes, such as to comply with our legal obligations,
              resolve disputes, and enforce our agreements. We also may retain and use any aggregate or de-identified information
              (i.e., information that does not identify you personally) for any purpose.
              <br />
              <br />
              If you no longer wish to use the Service, please discontinue use.
              <br />
              <br />
              <b>9. Privacy Rights and other information</b>
              <br />
              <br />
              Please see 3M’s Global Privacy Policy for more information, including information about rights you may have under
              applicable data protection laws.
              <br />
              <br />
              <b>10. How to Contact Us</b>
              <br />
              <br />
              E-mail: privacycompliance@mmm.com
              <br />
              By mail: 3M Company, Attention: Compliance and Business Conduct
              3M Center, Bldg. 220-11W-02, St. Paul, MN 55144-1000
              <br />
              Toll-free: 1-888-3M-HELPS (1-888-364-3577)
            </Typography>
          </Box>
          <Box display="flex" justifyContent="end" gap={4} mt={4}>
            <Button onClick={() => { handleClose() }}>{'Decline'}</Button>
            <Button variant="contained" disabled={!hasScrolledToBottomOnce || isLoading} onClick={() => { void handleAcceptEula() }}>
              {'Accept'}
            </Button>
          </Box>
        </ModalContent>
      </Modal>
    </Box>
  )
}
