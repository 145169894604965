import * as React from 'react'
import {
  Box,
  Breadcrumbs,
  Button,
  IconButton,
  InputAdornment,
  type TablePaginationProps,
  TextField,
  Tooltip,
  Typography
} from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import { Breadcrumb, StyledDataGrid } from '../../common/components'
import { Link, useParams } from 'react-router-dom'
import { type GridSortModel, type GridColDef, GridPagination } from '@mui/x-data-grid'
import {
  SystemApi,
  type SystemDto,
  ProjectApi
} from '../../common/services'
import { useQuery } from '@tanstack/react-query'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { useTranslation } from 'react-i18next'
import { ToastContainer, toast } from 'react-toastify'
import KeyTranslation from '../../common/KeyTranslation.json'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { v4 as uuidv4 } from 'uuid'
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'
import ContainerModal from './components/modalContainer'
import AlertDelete from '../../common/components/AlertDelete'
import MuiPagination from '@mui/material/Pagination'

export default function ProjectDetail (): JSX.Element {
  const { t } = useTranslation()
  const id = useParams()
  const projectId = Number(id.id)
  const [visible, setVisible] = React.useState(false)
  const [searchTerm, setSearchTerm] = React.useState<string>()
  const [sortBy, setSortBy] = React.useState({
    sortField: '',
    isDescending: true
  })
  const [dialogOpen, setDialogOpen] = React.useState({
    open: false,
    id: 0
  })
  const handleSortModelChange = (sortModel: GridSortModel): void => {
    if (sortModel != null && sortModel.length > 0) {
      const transformedSortModel = sortModel.map(item => ({
        sortField: item.field,
        isDescending: item.sort === 'desc' ? true : item.sort === 'asc' ? false : !sortBy.isDescending
      }))
      const [firstSortModel] = transformedSortModel
      setSortBy(firstSortModel)
      setPaginationModel({
        page: 0,
        pageSize: paginationModel.pageSize
      })
    }
  }
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 10
  })
  const {
    data: systemData,
    isLoading: systemIsLoading,
    isFetching: systemIsFetching,
    refetch: systemRefetch
  } = useQuery(
    ['/api/v2/System/SearchSystemsByPaging', projectId, paginationModel],
    async () => {
      if (projectId !== undefined) {
        const { data } =
          await new SystemApi().apiV2SystemSearchSystemsByPagingGet(
            projectId,
            paginationModel.page + 1,
            paginationModel.pageSize,
            searchTerm,
            sortBy.sortField,
            sortBy.isDescending
          )
        return data
      }
    },
    { enabled: false }
  )
  const project = useQuery(
    ['/api/v2/Project/GetProjectById', projectId],
    async () => {
      const { data } = await new ProjectApi().apiV2ProjectGetProjectByIdGet(
        projectId
      )
      return data.data
    },
    { enabled: false }
  )
  React.useEffect(() => {
    void project.refetch()
  }, [])
  React.useEffect(() => {
    void systemRefetch()
  }, [paginationModel, sortBy, searchTerm])
  const handleCopyToClipboard = (): void => {
    toast.success(t(KeyTranslation.Copy_Success))
  }
  const handleDialogClose = React.useCallback(() => {
    setDialogOpen({
      open: false,
      id: 0
    })
  }, [])
  const handleDuplicate = async (id: number): Promise<void> => {
    const { data: systemDataDetail } = await new SystemApi().apiV2SystemIdGet(id)
    const updatedSystem = { ...systemDataDetail?.data }
    if (updatedSystem.legs != null) {
      updatedSystem.legs = updatedSystem.legs.map((leg) => {
        const { id, ...legWithoutId } = leg
        if (legWithoutId.spans != null) {
          legWithoutId.spans = legWithoutId.spans.map((span) => {
            const { id, ...spanWithoutId } = span
            return spanWithoutId
          })
        }
        return legWithoutId
      })
    }
    const randomreference = uuidv4()

    try {
      const updatedSystemData = {
        ...updatedSystem,
        reference: randomreference
      }

      await new SystemApi().apiV2SystemPost(true, updatedSystemData)
      void systemRefetch()
      toast.success(t(KeyTranslation.Message_Duplicate_success))
    } catch (ex: any) {
      toast.error(t(ex.response.data.error))
    }
  }
  const handleDelete = React.useCallback(
    async (id: number) => {
      try {
        if (id != null) {
          await new SystemApi().apiV2SystemIdDelete(id)
          await systemRefetch()
          handleDialogClose()
          toast.success(t(KeyTranslation.Message_Delete_Success))
        }
      } catch (ex: any) {
        handleDialogClose()
        console.log(ex)
      }
    },
    [systemRefetch, handleDialogClose]
  )
  const columnsSystem: Array<GridColDef<SystemDto>> = [
    {
      field: 'systemName',
      headerName: t(KeyTranslation.Common_System_name) ?? '',
      flex: 1,
      renderCell: ({ row }) => {
        return (
          <Tooltip title={row.name}>
            <Link to={`/projects/${projectId ?? 0}/system/${row.id ?? 0}`}>
              {row.name}
            </Link>
          </Tooltip>
        )
      }
    },
    {
      field: 'applicationLocation',
      headerName: t(KeyTranslation.System_Popup_Application_location) ?? '',
      width: 200
    },
    {
      field: 'systemTypeName',
      headerName: t(KeyTranslation.Header_Type) ?? ''
    },
    {
      field: 'lastUpdatedBy',
      headerName: t(KeyTranslation.Header_LastUpdatedBy) ?? '',
      width: 150
    },
    {
      field: 'action',
      headerName: '',
      sortable: false,
      width: 220,
      renderCell: ({ row }) => {
        return (
          <Box display="flex" gap={3} alignItems={'center'}>
            <Tooltip title={t(KeyTranslation.Common_SystemOverview)}>
              <Link
                to={`/projects/${projectId ?? 0}/system-overview/${row.id ?? 0
                  }`}
              >
                <IconButton>
                  <VisibilityOutlinedIcon />
                </IconButton>
              </Link>
            </Tooltip>
            <Tooltip title={t(KeyTranslation.Btn_Edit)}>
              <IconButton>
                <Link
                  className="link"
                  to={`/projects/${projectId}/system/${row.id ?? 0}`}
                >
                  <EditOutlinedIcon />
                </Link>
              </IconButton>
            </Tooltip>
            <Tooltip title={t(KeyTranslation.Common_Duplicate)}>
              <IconButton onClick={() => {
                void handleDuplicate(Number(row.id))
              }}>
                <ContentCopyIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title={t(KeyTranslation.Btn_Delete)}>
              <IconButton
                onClick={() => {
                  setDialogOpen({
                    open: true,
                    id: row.id ?? 0
                  })
                }}
              >
                <DeleteOutlinedIcon />
              </IconButton>
            </Tooltip>
          </Box>
        )
      }
    }
  ]
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  function Pagination ({
    page,
    onPageChange,
    className
  }: Pick<TablePaginationProps, 'page' | 'onPageChange' | 'className'>) {
    const pageCount = Math.ceil((systemData?.totals ?? 0) / paginationModel.pageSize)
    return (
      <MuiPagination
        color="primary"
        className={className}
        count={pageCount}
        page={page + 1}
        onChange={(event, newPage) => {
          onPageChange(event as any, newPage - 1)
        }}
        showFirstButton
        showLastButton
      />
    )
  }

  function CustomPagination (props: any): JSX.Element {
    return <GridPagination ActionsComponent={Pagination} {...props} />
  }
  console.log(systemData?.data?.length)
  return (
    <Box>
      <Grid container spacing={2} mb={5} alignItems="center">
        <Grid xs={4}>
          <Typography variant="h1">{project.data?.projectName}</Typography>
        </Grid>
        <Grid display={'flex'} flexDirection={'column'} xs={4}>
          <Typography component={'span'} variant="caption">
            {t(KeyTranslation.Common_ProjectRef)}:{' '}
          </Typography>
          <Typography component={'span'}>{project.data?.reference}</Typography>
        </Grid>
        <Grid xs={4}>
          <Typography component={'span'} variant="caption">
            {t(KeyTranslation.Common_ProjectUrl)}:{' '}
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Typography component={'span'}>{window.location.href}</Typography>
            <CopyToClipboard
              onCopy={handleCopyToClipboard}
              text={window.location.href}
            >
              <Button size="small">{t(KeyTranslation.Common_Copy)}</Button>
            </CopyToClipboard>
          </Box>
        </Grid>
      </Grid>
      <Breadcrumbs aria-label="breadcrumb">
        <Breadcrumb
          component="div"
          label={<Link to={'/'}>{t(KeyTranslation.Common_ProjectsDashboard)}</Link>}
        />
        <Breadcrumb
          component="div"
          label={
            <Link to={`/projects/${Number(projectId)}`}>
              {t(KeyTranslation.Common_ProjectOverview)}
            </Link>
          }
        />
        <Breadcrumb component="div" label={t(KeyTranslation.Project_System)} />
      </Breadcrumbs>
      <Box sx={{ bgcolor: 'white', mt: 5 }} className="main">
        <Box className="tableTools">
          <Box className='flexCenter'>
            <Typography variant="h4">{t(KeyTranslation.Menu_Systems)}</Typography>
            <TextField
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchOutlinedIcon />
                  </InputAdornment>
                )
              }}
              variant="outlined"
              placeholder={t(KeyTranslation.Common_Search) ?? ''}
              size='small'
              value={searchTerm}
              onChange={(event) => {
                setSearchTerm(event.target.value)
              }} />
          </Box>
          <Box display="flex" gap={3}>
            <Tooltip title={t(KeyTranslation.Common_New_System)}>
              <IconButton
                onClick={() => {
                  setVisible(true)
                }}
              >
                <AddOutlinedIcon />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
        <Box>
          <StyledDataGrid
            autoHeight
            loading={systemIsLoading || systemIsFetching}
            rows={systemData?.data ?? []}
            columns={columnsSystem}
            rowSelection={false}
            disableColumnMenu={true}
            paginationMode="server"
            onPaginationModelChange={setPaginationModel}
            getRowId={(row) => row.id}
            rowCount={systemData?.totals ?? 0}
            paginationModel={paginationModel}
            onSortModelChange={(sortModel) => { handleSortModelChange(sortModel) }}
            slots={{
              pagination: CustomPagination
            }}
          />
        </Box>
        {visible && (
          <ContainerModal
            open={visible}
            onClose={() => {
              setVisible(false)
            }}
            systemRefetch={systemRefetch}
            unitId={project.data?.unitId}
            dataRroject={project.data}
          />
        )}
        <AlertDelete
          handleDelete={() => {
            void handleDelete(dialogOpen.id)
          }}
          dialogOpen={dialogOpen.open}
          handleDialogClose={handleDialogClose}
        />
      </Box>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </Box>
  )
}
