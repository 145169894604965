import React, { useState } from 'react'
import ModalContent from '../../../common/components/Modal'
import { Alert, Box, FormControl, FormControlLabel, Modal, Radio, RadioGroup, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { type CalculationResultForUserPosture, type ProjectDto } from '../../../common/services'
import { systemLayoutClass } from '../Helpers/Systemlayout'
import floor from '../../../assets/images/outputfloorlanyard.png'
import floorRaise from '../../../assets/images/outputfloorraisedlanyard.png'
import wall from '../../../assets/images/outputwalllanyard.png'
import roof from '../../../assets/images/outputrooflanyard.png'
import { type SrlLengthAtFall } from '../Helpers/CanvasProps'
import KeyTranslation from '../../../common/KeyTranslation.json'
interface outputDiagramProps {
  open: boolean
  onClose: (value?: number) => void
  systemCalculateResult: CalculationResultForUserPosture | undefined
  applicationLocationId: number | undefined
  projectData: ProjectDto
  spanResultIndex: number | undefined
  srlLengthAtFall: SrlLengthAtFall | undefined
  locationMetrics: string
  raise: string
  calMessage: string
  warning: string
}

export default function outputDiagram ({ open, onClose, systemCalculateResult, applicationLocationId, projectData, spanResultIndex, raise, srlLengthAtFall, locationMetrics, calMessage, warning }: outputDiagramProps): JSX.Element {
  const { t } = useTranslation()
  const [currentUserPosture, setCurrentUserPosture] = useState<string>('standing')
  const span = systemLayoutClass.getSpanDataSelectedFromSpan(currentUserPosture, spanResultIndex ?? 0, systemCalculateResult)
  const titleOutput = (): string | undefined => {
    switch (applicationLocationId) {
      case 1:
        return 'Output Diagram Roofhanging/Overhead'
      case 2:
        return 'Output Diagram Wall'
      case 3:
        return 'Output Diagram Floor-Raised'
      case 4:
        return 'OutputDiagramFloor'
    }
  }
  return (
        <Modal open={open}>
            <Box>
                <ModalContent width={900} onClose={onClose} title={t(titleOutput() ?? '')}>
                    <Box textAlign={'center'}>
                        <FormControl sx={{ mt: 2 }}>
                            <RadioGroup
                                row
                                value={currentUserPosture}
                                onChange={(event) => {
                                  setCurrentUserPosture(event.target.value)
                                }}
                            >
                                {
                                    Object.keys(systemCalculateResult ?? {}).map((key) => {
                                      const formattedKey = key.charAt(0).toUpperCase() + key.slice(1)
                                      if (key === 'standing') {
                                        return (
                                                <FormControlLabel key={key} value={key} control={<Radio />} label={<strong>{t(formattedKey)}</strong>} />
                                        )
                                      }
                                      return null
                                    })
                                }

                            </RadioGroup>
                        </FormControl>
                        <Typography>
                            <span style={{ fontWeight: 'bold' }}>{`${t(KeyTranslation.Span)}:`}</span>
                            {` ${span?.spanName ?? ''}`}
                        </Typography>
                        <Typography>
                            <span style={{ fontWeight: 'bold' }}>{`${t(KeyTranslation.TFD)}:`}</span>
                            {` ${systemLayoutClass.getTotalFallDistance(systemLayoutClass.getSpanDataSelectedFromSpan(currentUserPosture, spanResultIndex ?? 0, systemCalculateResult)) ?? 0} ${projectData?.unitId === 2 ? 'ft' : 'm'}`}
                        </Typography>

                        <Typography>
                            <span style={{ fontWeight: 'bold' }}>{`${t(KeyTranslation.Peak_Lifeline_Tension)}:`}</span>
                            {` ${systemLayoutClass.getSpanDataSelectedFromSpan(currentUserPosture, spanResultIndex ?? 0, systemCalculateResult)?.peakhllTension ?? 0} ${projectData?.unitId === 2 ? ' lb' : 'kN'}`}</Typography>
                        <Typography>
                            <span style={{ fontWeight: 'bold' }}>{`${t(KeyTranslation.Peak_Force_On_User)}:`}</span>
                            {` ${systemLayoutClass.getSpanDataSelectedFromSpan(currentUserPosture, spanResultIndex ?? 0, systemCalculateResult)?.peakForceOnUser ?? 0} ${projectData?.unitId === 2 ? ' lb' : 'kN'}`}</Typography>
                        {applicationLocationId === 4 && (
                            <Box px={40} pt={20} className='floor-diagram'>
                                <img width={'600px'} src={floor} alt="" />
                                <Typography className='floor-hhl'>
                                    {`${t(KeyTranslation.HLL_Deflection)}: `}<br />
                                    {`${span?.lifeLineDeflection ?? 0} ${projectData?.unitId === 2 ? 'ft' : 'm'}`}
                                </Typography>
                                {span?.pea?.issrl === true && (
                                    <Typography className='floor-fall'>
                                        {`${t(KeyTranslation.SRL_length_at_fall)}: `}<br />
                                        {`${srlLengthAtFall?.[currentUserPosture] ?? 0} ${projectData?.unitId === 2 ? 'ft' : 'm'}`}</Typography>
                                )}
                                {span?.pea?.issrl === false && (
                                    <Typography className='floor-fall'>
                                        {`${t(KeyTranslation.Lanyard_length_at_fall)}: `}<br />
                                        {`${span?.lengthOfLanyardOrActivationDistanceOfSRL ?? 0} ${projectData?.unitId === 2 ? 'ft' : 'm'}`}</Typography>
                                )}
                                {span?.pea?.issrl === true && (
                                    <Typography className='floor-srl'>{`${t(KeyTranslation.SRL_Arrest_Distance)}: ${(Number(span?.srlArrestDistance))} ${projectData?.unitId === 2 ? 'ft' : 'm'}`}</Typography>
                                )}
                                {span?.pea?.issrl === false && (
                                    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                                    <Typography className='floor-srl'>{`${t(KeyTranslation.PEA_Deployment)}: ${(Number(span?.srlArrestDistance))} ${projectData?.unitId === 2 ? 'ft' : 'm'}`}</Typography>
                                )}
                                <Typography className='floor-harness'>
                                    {`${t(KeyTranslation.Harness_Worker_Stretch)}: `}<br />
                                    {` ${span?.harnessStretch ?? 0} ${projectData?.unitId === 2 ? 'ft' : 'm'}`}</Typography>
                                <Typography className='floor-platform'>
                                    {`${t(KeyTranslation.Required_Clearance_Below_The_Platform)}: `}<br />
                                    {`${span?.requiredClearanceBelowThePlatform ?? 0} ${projectData?.unitId === 2 ? 'ft' : 'm'}`}</Typography>
                                <Typography className='floor-margin'>
                                    {`${t(KeyTranslation.Safety_Margin)}: `}<br />
                                    {`${span?.safetyMargin ?? 0} ${projectData?.unitId === 2 ? 'ft' : 'm'}`}</Typography>
                                <Typography className={`floor-remaining ${(span?.clearanceRemaining ?? 0) < 0 ? 'text-red' : ''}`}>
                                    {`${t(KeyTranslation.Clearance_Remaining)}: `}<br />
                                    {`${span?.clearanceRemaining ?? 0} ${projectData?.unitId === 2 ? 'ft' : 'm'}`}</Typography>
                                <Typography className='floor-metrics' fontWeight={'bold'}>
                                    {locationMetrics}{projectData?.unitId === 2 ? ' ft' : ' m'}
                                </Typography>
                            </Box>
                        )}
                        {applicationLocationId === 3 && (
                            <Box px={40} pt={20} className='raise-diagram'>
                                <img width={'600px'} src={floorRaise} alt="" />
                                <Typography className='raise-hhl'>
                                    {`${t(KeyTranslation.HLL_Deflection)}: `}<br />
                                    {`${span?.lifeLineDeflection ?? 0} ${projectData?.unitId === 2 ? 'ft' : 'm'}`}
                                </Typography>
                                {span?.pea?.issrl === true && (
                                    <Typography className='raise-fall'>
                                        {`${t(KeyTranslation.SRL_length_at_fall)}: `}<br />
                                        {`${srlLengthAtFall?.[currentUserPosture] ?? 0} ${projectData?.unitId === 2 ? 'ft' : 'm'}`}</Typography>
                                )}
                                {span?.pea?.issrl === false && (
                                    <Typography className='raise-fall'>
                                        {`${t(KeyTranslation.Lanyard_length_at_fall)}: `}<br />
                                        {`${span?.lengthOfLanyardOrActivationDistanceOfSRL ?? 0} ${projectData?.unitId === 2 ? 'ft' : 'm'}`}</Typography>
                                )}
                                {span?.pea?.issrl === true && (
                                    <Typography className='raise-srl'>{`${t(KeyTranslation.SRL_Arrest_Distance)}: ${(Number(span?.srlArrestDistance))} ${projectData?.unitId === 2 ? 'ft' : 'm'}`}</Typography>
                                )}
                                {span?.pea?.issrl === false && (
                                    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                                    <Typography className='raise-srl'>{`${t(KeyTranslation.PEA_Deployment)}: ${(Number(span?.srlArrestDistance))} ${projectData?.unitId === 2 ? 'ft' : 'm'}`}</Typography>
                                )}
                                <Typography className='raise-harness'>
                                    {`${t(KeyTranslation.Harness_Worker_Stretch)}: `}<br />
                                    {` ${span?.harnessStretch ?? 0} ${projectData?.unitId === 2 ? 'ft' : 'm'}`}</Typography>
                                <Typography className='raise-platform'>
                                    {`${t(KeyTranslation.Required_Clearance_Below_The_Platform)}: `}<br />
                                    {`${span?.requiredClearanceBelowThePlatform ?? 0} ${projectData?.unitId === 2 ? 'ft' : 'm'}`}</Typography>
                                <Typography className='raise-margin'>
                                    {`${t(KeyTranslation.Safety_Margin)}: `}<br />
                                    {`${span?.safetyMargin ?? 0} ${projectData?.unitId === 2 ? 'ft' : 'm'}`}</Typography>
                                <Typography className={`raise-remaining ${(span?.clearanceRemaining ?? 0) < 0 ? 'text-red' : ''}`}>
                                    {`${t(KeyTranslation.Clearance_Remaining)}: `}<br />
                                    {`${span?.clearanceRemaining ?? 0} ${projectData?.unitId === 2 ? 'ft' : 'm'}`}</Typography>
                                <Typography className='raise-metrics' fontWeight={'bold'}>
                                    {locationMetrics}{projectData?.unitId === 2 ? ' ft' : ' m'}
                                </Typography>
                                <Typography className='raise-raise' fontWeight={'bold'}>
                                    {raise}{projectData?.unitId === 2 ? ' ft' : ' m'}
                                </Typography>
                            </Box>
                        )}
                        {applicationLocationId === 2 && (
                            <Box px={40} pt={20} className='wall-diagram'>
                                <img width={'600px'} src={wall} alt="" />
                                <Typography className='wall-hhl'>
                                    {`${t(KeyTranslation.HLL_Deflection)}: `}<br />
                                    {`${span?.lifeLineDeflection ?? 0} ${projectData?.unitId === 2 ? 'ft' : 'm'}`}
                                </Typography>
                                {span?.pea?.issrl === true && (
                                    <Typography className='wall-fall'>
                                        {`${t(KeyTranslation.SRL_length_at_fall)}: `}<br />
                                        {`${srlLengthAtFall?.[currentUserPosture] ?? 0} ${projectData?.unitId === 2 ? 'ft' : 'm'}`}</Typography>
                                )}
                                {span?.pea?.issrl === false && (
                                    <Typography className='wall-fall'>
                                        {`${t(KeyTranslation.Lanyard_length_at_fall)}: `}<br />
                                        {`${span?.lengthOfLanyardOrActivationDistanceOfSRL ?? 0} ${projectData?.unitId === 2 ? 'ft' : 'm'}`}</Typography>
                                )}
                                {span?.pea?.issrl === true && (
                                    <Typography className='wall-srl'>{`${t(KeyTranslation.SRL_Arrest_Distance)}: ${(Number(span?.srlArrestDistance))} ${projectData?.unitId === 2 ? 'ft' : 'm'}`}</Typography>
                                )}
                                {span?.pea?.issrl === false && (
                                    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                                    <Typography className='wall-srl'>{`${t(KeyTranslation.PEA_Deployment)}: ${(Number(span?.srlArrestDistance))} ${projectData?.unitId === 2 ? 'ft' : 'm'}`}</Typography>
                                )}
                                <Typography className='wall-harness'>
                                    {`${t(KeyTranslation.Harness_Worker_Stretch)}: `}<br />
                                    {` ${span?.harnessStretch ?? 0} ${projectData?.unitId === 2 ? 'ft' : 'm'}`}</Typography>
                                <Typography className='wall-platform'>
                                    {`${t(KeyTranslation.Required_Clearance_Below_The_Platform)}: `}<br />
                                    {`${span?.requiredClearanceBelowThePlatform ?? 0} ${projectData?.unitId === 2 ? 'ft' : 'm'}`}</Typography>
                                <Typography className='wall-margin'>
                                    {`${t(KeyTranslation.Safety_Margin)}: `}<br />
                                    {`${span?.safetyMargin ?? 0} ${projectData?.unitId === 2 ? 'ft' : 'm'}`}</Typography>
                                <Typography className={`wall-remaining ${(span?.clearanceRemaining ?? 0) < 0 ? 'text-red' : ''}`}>
                                    {`${t(KeyTranslation.Clearance_Remaining)}: `}<br />
                                    {`${span?.clearanceRemaining ?? 0} ${projectData?.unitId === 2 ? 'ft' : 'm'}`}
                                </Typography>

                                <Typography className='wall-metrics' fontWeight={'bold'}>
                                    {locationMetrics}{projectData?.unitId === 2 ? ' ft' : ' m'}
                                </Typography>
                                <Typography className='wall-raise' fontWeight={'bold'}>
                                    {raise}{projectData?.unitId === 2 ? ' ft' : ' m'}
                                </Typography>
                            </Box>
                        )}
                        {applicationLocationId === 1 && (
                            <Box px={40} pt={20} className='roof-diagram'>
                                <img width={'600px'} src={roof} alt="" />
                                <Typography className='roof-hhl'>
                                    {`${t(KeyTranslation.HLL_Deflection)}: `}<br />
                                    {`${span?.lifeLineDeflection ?? 0} ${projectData?.unitId === 2 ? 'ft' : 'm'}`}
                                </Typography>
                                {span?.pea?.issrl === true && (
                                    <Typography className='roof-fall'>
                                        {`${t(KeyTranslation.SRL_length_at_fall)}: `}<br />
                                        {`${srlLengthAtFall?.[currentUserPosture] ?? 0} ${projectData?.unitId === 2 ? 'ft' : 'm'}`}</Typography>
                                )}
                                {span?.pea?.issrl === false && (
                                    <Typography className='roof-fall'>
                                        {`${t(KeyTranslation.Lanyard_length_at_fall)}: `}<br />
                                        {`${span?.lengthOfLanyardOrActivationDistanceOfSRL ?? 0} ${projectData?.unitId === 2 ? 'ft' : 'm'}`}</Typography>
                                )}
                                {span?.pea?.issrl === true && (
                                    <Typography className='roof-srl'>{`${t(KeyTranslation.SRL_Arrest_Distance)}: ${(Number(span?.srlArrestDistance))} ${projectData?.unitId === 2 ? 'ft' : 'm'}`}</Typography>
                                )}
                                {span?.pea?.issrl === false && (
                                    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                                    <Typography className='roof-srl'>{`${t(KeyTranslation.PEA_Deployment)}: ${(Number(span?.srlArrestDistance))} ${projectData?.unitId === 2 ? 'ft' : 'm'}`}</Typography>
                                )}
                                <Typography className='roof-harness'>
                                    {`${t(KeyTranslation.Harness_Worker_Stretch)}: `}<br />
                                    {` ${span?.harnessStretch ?? 0} ${projectData?.unitId === 2 ? 'ft' : 'm'}`}</Typography>
                                <Typography className='roof-platform'>
                                    {`${t(KeyTranslation.Required_Clearance_Below_The_Platform)}: `}<br />
                                    {`${span?.requiredClearanceBelowThePlatform ?? 0} ${projectData?.unitId === 2 ? 'ft' : 'm'}`}</Typography>
                                <Typography className='roof-margin'>
                                    {`${t(KeyTranslation.Safety_Margin)}: `}<br />
                                    {`${span?.safetyMargin ?? 0} ${projectData?.unitId === 2 ? 'ft' : 'm'}`}</Typography>
                                <Typography
                                    className={`roof-remaining ${(span?.clearanceRemaining ?? 0) < 0 ? 'text-red' : ''}`}
                                >
                                    {`${t(KeyTranslation.Clearance_Remaining)}: `}<br />
                                    {`${span?.clearanceRemaining ?? 0} ${projectData?.unitId === 2 ? 'ft' : 'm'}`}
                                </Typography>

                                <Typography className='roof-metrics' fontWeight={'bold'}>
                                    {locationMetrics}{projectData?.unitId === 2 ? ' ft' : ' m'}
                                </Typography>
                                <Typography className='roof-raise' fontWeight={'bold'}>
                                    {raise}{projectData?.unitId === 2 ? ' ft' : ' m'}
                                </Typography>
                            </Box>
                        )}
                        {calMessage !== '' && (
                            <Alert sx={{ mt: 2 }} severity="error">{`${t(KeyTranslation.Common_Standing)}: ${t(calMessage)}`}</Alert>
                        )}
                        {warning !== '' && (
                            <Alert sx={{ mt: 2 }} severity="warning">{`${t(KeyTranslation.Common_Standing)}: ${t(warning)}`}</Alert>
                        )}
                    </Box>
                </ModalContent>
            </Box>
        </Modal>
  )
}
