import * as React from 'react'
import { Box, Button, Checkbox, IconButton, InputAdornment, type TablePaginationProps, TextField, Tooltip, Typography } from '@mui/material'
import { type GridSortModel, type GridColDef, GridPagination, type GridColumnVisibilityModel, getGridSingleSelectOperators, GridToolbar } from '@mui/x-data-grid'
import { StyledDataGrid } from '../../common/components'
import { Link } from 'react-router-dom'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'
import { useQuery } from '@tanstack/react-query'
import { CommonApi, LanguageApi, type LanguageDto, ProjectApi, RegionApi, type RegionDto, type StandardDto, type ProjectDto, type CommonDto } from '../../common/services'
import { useTranslation } from 'react-i18next'
import AlertDelete from '../../common/components/AlertDelete'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import moment from 'moment'
import { ModalProject } from './components'
import { ToastContainer, toast } from 'react-toastify'
import KeyTranslation from '../../common/KeyTranslation.json'
import MuiPagination from '@mui/material/Pagination'
import FavoriteBorder from '@mui/icons-material/StarBorderOutlined'
import Favorite from '@mui/icons-material/StarOutlined'

const initialColumnVisibility: GridColumnVisibilityModel = {
  projectName: true,
  companyName: true,
  customerName: true,
  languageName: false,
  regionName: false,
  standardName: false,
  unitName: false,
  updatedBy: true,
  LastUpdatedDate: true,
  createdBy: true,
  type: false
}
interface projectTypeProp {
  name: string
  value: number
}
const projectType = [
  { name: 'All projects', value: 0 },
  { name: 'My projects', value: 1 },
  { name: 'My favorite projects', value: 2 }
]
export default function Projects (): JSX.Element {
  const { t } = useTranslation()
  const [value, setValue] = React.useState('recent')
  const [projectModalProps, setProjectModalProps] = React.useState<{ visible: boolean, data?: ProjectDto, isEditing: boolean }>({
    visible: false,
    data: undefined,
    isEditing: false
  })
  const [dialogOpen, setDialogOpen] = React.useState({
    open: false,
    id: 0
  })
  const [searchTerm, setSearchTerm] = React.useState<string>()
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 10
  })
  const [filterValues, setFilterValues] = React.useState<any>({
    regionId: 0,
    standardId: 0,
    unitId: 0,
    languageId: 0,
    type: 0
  })
  const [sortBy, setSortBy] = React.useState({
    sortField: 'LastUpdatedDate',
    isDescending: true
  })
  const [columnVisibility, setColumnVisibility] = React.useState(initialColumnVisibility)
  const handleColumnVisibilityChange = (newVisibility: GridColumnVisibilityModel): any => {
    setColumnVisibility(newVisibility)
  }
  const handleSortModelChange = (sortModel: GridSortModel): void => {
    if (sortModel != null && sortModel.length > 0) {
      const transformedSortModel = sortModel.map(item => ({
        sortField: item.field,
        isDescending: item.sort === 'desc' ? true : item.sort === 'asc' ? false : !sortBy.isDescending
      }))
      const [firstSortModel] = transformedSortModel
      setSortBy(firstSortModel)
      setPaginationModel({
        page: 0,
        pageSize: paginationModel.pageSize
      })
    }
  }

  const common = useQuery(['/api/v2/Common'], async () => {
    const res = await new CommonApi().apiV2CommonGet()
    return res.data
  }, { enabled: value === 'all' })

  const region = useQuery(['/api/v2/Region/GetAll'], async () => {
    const res = await new RegionApi().apiV2RegionGetAllGet()
    return res.data
  }, { enabled: value === 'all' })

  const language = useQuery(['/api/v2/Language'], async () => {
    const res = await new LanguageApi().apiV2LanguageGet()
    return res.data
  }, { enabled: value === 'all' })
  React.useEffect(() => {
    if (value === 'all') {
      void common.refetch()
      void region.refetch()
      void language.refetch()
    }
  }, [])

  const { data: recentData, isLoading: recentLoading, refetch: refetchRecent } = useQuery(
    ['/api/v2/Project/GetRecentProject'],
    async () => {
      const { data } = await new ProjectApi().apiV2ProjectGetRecentProjectGet()
      return data
    },
    { enabled: value === 'recent' }
  )

  const { data: allData, isLoading: allLoading, isFetching, refetch: refetchAll } = useQuery(
    ['/api/v2/Project/GetProjectsByPaging'],
    async () => {
      const { data } = await new ProjectApi().apiV2ProjectGetProjectsByPagingGet(
        0, filterValues.regionId, filterValues.languageId, filterValues.standardId, filterValues.unitId, [], filterValues.type,
        paginationModel.page + 1, paginationModel.pageSize, searchTerm, sortBy.sortField, sortBy.isDescending
      )
      return data
    },
    { enabled: value === 'all' }
  )
  const data = value === 'recent' ? recentData : allData
  const isLoading = value === 'recent' ? recentLoading : allLoading
  const isRefetch = value === 'recent' ? refetchRecent : refetchAll
  const handleChange = (event: React.SyntheticEvent, newValue: string): void => {
    setValue(newValue)
    if (newValue === 'recent') {
      setPaginationModel({ page: 0, pageSize: 10 })
      setSearchTerm('')
    }
  }

  const handleDialogClose = React.useCallback(() => {
    setDialogOpen({
      open: false,
      id: 0
    })
  }, [])

  const handleDelete = React.useCallback(async (id: number) => {
    try {
      await new ProjectApi().apiV2ProjectIdDelete(id)
      if (value === 'recent') {
        await refetchRecent()
      } else {
        await refetchAll()
      }
      handleDialogClose()
      toast.success(t(KeyTranslation.Message_Delete_Success))
    } catch (ex: any) {
      handleDialogClose()
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      toast.error(t(`${ex.response.data.error}`))
    }
  }, [refetchRecent, refetchAll, handleDialogClose, value])
  const handleFavorite = async (id: number): Promise<void> => {
    try {
      await new ProjectApi().apiV2ProjectToggleFavoriteIdPut(id)
      if (value === 'recent') {
        void refetchRecent()
      } else {
        void refetchAll()
      }
    } catch (error) {
      console.log(error)
    }
  }
  React.useEffect(() => {
    if (value === 'recent') {
      void refetchRecent()
    } else {
      void refetchAll()
    }
  }, [searchTerm, paginationModel, sortBy, value, filterValues.languageId, filterValues.standardId, filterValues.regionId, filterValues.unitId, filterValues.type])
  const columns: Array<GridColDef<ProjectDto>> = [
    {
      field: 'projectName',
      headerName: t(KeyTranslation.Header_ProjectName) ?? '',
      filterable: false,
      minWidth: 200,
      flex: 1,
      renderCell: ({ row }) => {
        return <Link title={row.projectName ?? ''} to={`/projects/${row.id?.toString() ?? ''}`} className="ellipsisBaseComponent">{row.projectName}</Link>
      }
    },
    {
      field: 'companyName',
      headerName: t(KeyTranslation.client_name) ?? '',
      filterable: false,
      minWidth: 150,
      flex: 1
    },
    {
      field: 'customerName',
      headerName: t(KeyTranslation.Common_CustomerName) ?? '',
      filterable: false,
      minWidth: 150,
      flex: 1
    },
    {
      field: 'type',
      headerName: t(KeyTranslation.Select_Project_Type) ?? '',
      type: 'singleSelect',
      valueFormatter: value => value.value,
      filterOperators: getGridSingleSelectOperators().filter(
        (operator) => operator.value === 'is'
      ),
      valueOptions: projectType?.map(
        (item: projectTypeProp) => ({
          label: item.name,
          value: item.value,
          key: item.value
        })
      ),
      minWidth: 150,
      flex: 1,
      hideable: false
    },
    {
      field: 'languageName',
      headerName: t(KeyTranslation.Header_LaguageName) ?? '',
      type: 'singleSelect',
      valueFormatter: value => value.value,
      filterOperators: getGridSingleSelectOperators().filter(
        (operator) => operator.value === 'is'
      ),
      valueOptions: language.data?.data?.map(
        (item: LanguageDto) => ({
          label: item.name,
          value: item.id,
          key: item.id
        })
      ),
      minWidth: 150,
      flex: 1
    },
    {
      field: 'regionName',
      headerName: t(KeyTranslation.Header_RegionName) ?? '',
      type: 'singleSelect',
      valueFormatter: value => value.value,
      filterOperators: getGridSingleSelectOperators().filter(
        (operator) => operator.value === 'is'
      ),
      valueOptions: region.data?.data?.map(
        (region: RegionDto) => ({
          label: region.name,
          value: region.id,
          key: region.id
        })
      ),
      minWidth: 150,
      flex: 1
    },
    {
      field: 'standardName',
      headerName: t(KeyTranslation.Standard_StandardName) ?? '',
      type: 'singleSelect',
      valueFormatter: value => value.value,
      filterOperators: getGridSingleSelectOperators().filter(
        (operator) => operator.value === 'is'
      ),
      valueOptions: common.data?.data?.standards?.map(
        (item: StandardDto) => ({
          label: item.name,
          value: item.id,
          key: item.id
        })
      ),
      minWidth: 150,
      flex: 1
    },
    {
      field: 'unitName',
      headerName: t(KeyTranslation.Header_UnitName) ?? '',
      type: 'singleSelect',
      valueFormatter: value => value.value,
      filterOperators: getGridSingleSelectOperators().filter(
        (operator) => operator.value === 'is'
      ),
      valueOptions: common.data?.data?.units?.map(
        (item: CommonDto) => ({
          label: item.name,
          value: item.id,
          key: item.id
        })
      ),
      minWidth: 150,
      flex: 1
    },
    {
      field: 'createdBy',
      headerName: t(KeyTranslation.Common_Create_by) ?? '',
      filterable: false,
      width: 100
    },
    {
      field: 'updatedBy',
      headerName: t(KeyTranslation.Header_LastUpdatedBy) ?? '',
      filterable: false,
      width: 100
    },
    {
      field: 'LastUpdatedDate',
      headerName: t(KeyTranslation.Header_LastUpdatedDate) ?? '',
      filterable: false,
      width: 150,
      renderCell: ({ row }) => {
        const inputFormat = 'YYYY-MM-DDTHH:mm:ss.SSS'
        const outputFormat = 'YYYY-MM-DD HH:mm'
        const convertedTime = moment(row.updated, inputFormat).format(outputFormat)
        return <div>{convertedTime}</div>
      }
    },
    {
      field: 'action',
      headerName: '',
      sortable: false,
      filterable: false,
      width: 150,
      renderCell: ({ row }) => {
        return <Box display="flex" gap={3}>
          <Tooltip title={t(KeyTranslation.Favourite)}>
          <Checkbox checked={row.isFavorite} icon={<FavoriteBorder />} checkedIcon={<Favorite color='warning' />} onChange={() => { void handleFavorite(Number(row.id)) }} />
          </Tooltip>
          <Tooltip title={t(KeyTranslation.Btn_Edit)}>
            <IconButton onClick={() => {
              setProjectModalProps({
                isEditing: true,
                visible: true,
                data: row
              })
            }}>
              <EditOutlinedIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title={t(KeyTranslation.Btn_Delete)}>
            <IconButton onClick={() => {
              setDialogOpen({
                open: true,
                id: row.id ?? 0
              })
            }}>
              <DeleteOutlinedIcon />
            </IconButton>
          </Tooltip>
        </Box>
      }
    }
  ]
  const handleFilterModelChange = async (model: any): Promise<void> => {
    try {
      const updatedFilterValues: any = {
        regionId: 0,
        standardId: 0,
        unitId: 0,
        languageId: 0,
        type: 0
      }
      model.items.forEach((item: any) => {
        const { field, operator, value } = item
        switch (field) {
          case 'type':
            if (operator === 'is') {
              if (value != null) {
                updatedFilterValues.type = [value]
              }
            }
            break
          case 'regionName':
            if (operator === 'is') {
              if (value != null) {
                updatedFilterValues.regionId = [value]
              }
            }
            break
          case 'standardName':
            if (operator === 'is') {
              if (value != null) {
                updatedFilterValues.standardId = [value]
              }
            }
            break
          case 'unitName':
            if (operator === 'is') {
              if (value != null) {
                updatedFilterValues.unitId = [value]
              }
            }
            break
          case 'languageName':
            if (operator === 'is') {
              if (value != null) {
                updatedFilterValues.languageId = [value]
              }
            }
            break
          default:
            break
        }
      })
      setFilterValues(updatedFilterValues)
      setPaginationModel({
        page: 0,
        pageSize: paginationModel.pageSize
      })
    } catch (error) {
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  function Pagination ({
    page,
    onPageChange,
    className
  }: Pick<TablePaginationProps, 'page' | 'onPageChange' | 'className'>) {
    const pageCount = Math.ceil((allData?.totals ?? 0) / paginationModel.pageSize)
    return (
      <MuiPagination
        color="primary"
        className={className}
        count={pageCount}
        page={page + 1}
        onChange={(event, newPage) => {
          onPageChange(event as any, newPage - 1)
        }}
        showFirstButton
        showLastButton
      />
    )
  }

  function CustomPagination (props: any): JSX.Element {
    return <GridPagination ActionsComponent={Pagination} {...props} />
  }

  const filteredData = React.useMemo(() => {
    if (data == null) {
      return []
    }

    if (searchTerm?.length === 0) {
      return data.data
    }

    return data.data?.filter((project: ProjectDto) => {
      const searchTermLower = searchTerm?.toLowerCase()
      return ((project.companyName?.toLowerCase().includes(searchTermLower ?? '')) ?? false) ||
        ((project.projectName?.toLowerCase().includes(searchTermLower ?? '')) ?? false) || project.customerName?.toLowerCase().includes(searchTermLower ?? '')
    })
  }, [data, searchTerm])
  return (
    <Box>
      <Typography variant='h1'>{t(KeyTranslation.Common_ProjectsManagement)}</Typography>
      <Box sx={{ width: '100%', mt: 5 }}>
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          <Tab value="recent" label={t(KeyTranslation.Dashboard_Recent_Projects)} />
          <Tab value="all" label={t(KeyTranslation.Dashboard_All_Projects)} />
        </Tabs>
      </Box>
      <Box sx={{ mt: 5 }} className="main">
        <Box sx={{ mb: 5, minHeight: '39px' }} display="flex" justifyContent="space-between">
          {value !== 'recent' && (
            <Box className='flexCenter'>
              <TextField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchOutlinedIcon />
                    </InputAdornment>
                  )
                }}
                variant="outlined"
                placeholder={t(KeyTranslation.Common_Search) ?? ''}
                size='small'
                value={searchTerm}
                onChange={(event) => {
                  setSearchTerm(event.target.value)
                }} />
            </Box>
          )}
          <Button sx={{ marginLeft: 'auto' }} variant="contained" onClick={() => { setProjectModalProps({ visible: true, data: undefined, isEditing: false }) }}>{t(KeyTranslation.Dashboard_New_Project)}</Button>
        </Box>
        <Box>
          <StyledDataGrid
            autoHeight
            loading={isLoading || isFetching}
            paginationModel={paginationModel}
            rows={filteredData ?? []}
            rowCount={data?.totals ?? 0}
            columns={columns}
            paginationMode="server"
            onPaginationModelChange={setPaginationModel}
            hideFooterPagination={value === 'recent'}
            sortingMode="server"
            filterMode="server"
            columnVisibilityModel={columnVisibility}
            onColumnVisibilityModelChange={handleColumnVisibilityChange}
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onFilterModelChange={handleFilterModelChange}
            onSortModelChange={(sortModel) => { handleSortModelChange(sortModel) }}
            slots={{
              pagination: CustomPagination,
              toolbar: value !== 'recent' ? GridToolbar : undefined
            }}
            initialState={{
              filter: {
                filterModel: {
                  items: [{ field: 'type', operator: 'is', value: 0 }]
                }
              }
            }}
          />
        </Box>
      </Box>
      {projectModalProps.visible && (
        <ModalProject isEditing={projectModalProps.isEditing} project={projectModalProps.data} open={projectModalProps.visible} refetch={isRefetch} onClose={(): void => {
          setProjectModalProps({
            visible: false,
            data: undefined,
            isEditing: false
          })
        }}></ModalProject>
      )}
      <AlertDelete handleDelete={() => { void handleDelete(dialogOpen.id) }} dialogOpen={dialogOpen.open} handleDialogClose={handleDialogClose} />
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </Box>
  )
}
