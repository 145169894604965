/* tslint:disable */
/* eslint-disable */
/**
 * ESDS.Web.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CalculationForIntelDto } from '../models';
// @ts-ignore
import { CalculationResultForUserPostureApiResponse } from '../models';
// @ts-ignore
import { Int32ApiResponse } from '../models';
// @ts-ignore
import { LegDtoIEnumerableApiResponse } from '../models';
// @ts-ignore
import { MaxSpanForAllLegsApiResponse } from '../models';
// @ts-ignore
import { MaxSpanForLegApiResponse } from '../models';
// @ts-ignore
import { SmartComponentDesignationDto } from '../models';
// @ts-ignore
import { StringApiResponse } from '../models';
// @ts-ignore
import { SystemDtoIEnumerableApiResponse } from '../models';
// @ts-ignore
import { SystemInfoDto } from '../models';
// @ts-ignore
import { SystemInfoDtoApiResponse } from '../models';
/**
 * SystemApi - axios parameter creator
 * @export
 */
export const SystemApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [projectUnit] 
         * @param {SystemInfoDto} [systemInfoDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV2SystemCalculationForAllPost: async (projectUnit?: number, systemInfoDto?: SystemInfoDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/System/CalculationForAll`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (projectUnit !== undefined) {
                localVarQueryParameter['projectUnit'] = projectUnit;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemInfoDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [projectUnit] 
         * @param {CalculationForIntelDto} [calculationForIntelDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV2SystemCalculationForIntelPost: async (projectUnit?: number, calculationForIntelDto?: CalculationForIntelDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/System/CalculationForIntel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (projectUnit !== undefined) {
                localVarQueryParameter['projectUnit'] = projectUnit;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(calculationForIntelDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SystemInfoDto} [systemInfoDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV2SystemCalculationPost: async (systemInfoDto?: SystemInfoDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/System/Calculation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemInfoDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV2SystemGetAllProjectIdGet: async (projectId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('apiV2SystemGetAllProjectIdGet', 'projectId', projectId)
            const localVarPath = `/api/v2/System/GetAll/{projectId}`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV2SystemIdDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV2SystemIdDelete', 'id', id)
            const localVarPath = `/api/v2/System/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV2SystemIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV2SystemIdGet', 'id', id)
            const localVarPath = `/api/v2/System/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {boolean} [isDuplicate] 
         * @param {SystemInfoDto} [systemInfoDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV2SystemPost: async (isDuplicate?: boolean, systemInfoDto?: SystemInfoDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/System`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (isDuplicate !== undefined) {
                localVarQueryParameter['isDuplicate'] = isDuplicate;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemInfoDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [projectUnit] 
         * @param {SystemInfoDto} [systemInfoDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV2SystemPut: async (projectUnit?: number, systemInfoDto?: SystemInfoDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/System`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (projectUnit !== undefined) {
                localVarQueryParameter['projectUnit'] = projectUnit;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemInfoDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [projectId] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [search] 
         * @param {string} [sortField] 
         * @param {boolean} [isDescending] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV2SystemSearchSystemsByPagingGet: async (projectId?: number, page?: number, pageSize?: number, search?: string, sortField?: string, isDescending?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/System/SearchSystemsByPaging`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (projectId !== undefined) {
                localVarQueryParameter['ProjectId'] = projectId;
            }

            if (page !== undefined) {
                localVarQueryParameter['Page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (search !== undefined) {
                localVarQueryParameter['Search'] = search;
            }

            if (sortField !== undefined) {
                localVarQueryParameter['SortField'] = sortField;
            }

            if (isDescending !== undefined) {
                localVarQueryParameter['IsDescending'] = isDescending;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SmartComponentDesignationDto} [smartComponentDesignationDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV2SystemSmartComponentDesignationPost: async (smartComponentDesignationDto?: SmartComponentDesignationDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/System/SmartComponentDesignation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(smartComponentDesignationDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV2SystemUniqueStringGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/System/UniqueString`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SystemApi - functional programming interface
 * @export
 */
export const SystemApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SystemApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} [projectUnit] 
         * @param {SystemInfoDto} [systemInfoDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV2SystemCalculationForAllPost(projectUnit?: number, systemInfoDto?: SystemInfoDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MaxSpanForAllLegsApiResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV2SystemCalculationForAllPost(projectUnit, systemInfoDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [projectUnit] 
         * @param {CalculationForIntelDto} [calculationForIntelDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV2SystemCalculationForIntelPost(projectUnit?: number, calculationForIntelDto?: CalculationForIntelDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MaxSpanForLegApiResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV2SystemCalculationForIntelPost(projectUnit, calculationForIntelDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SystemInfoDto} [systemInfoDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV2SystemCalculationPost(systemInfoDto?: SystemInfoDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CalculationResultForUserPostureApiResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV2SystemCalculationPost(systemInfoDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV2SystemGetAllProjectIdGet(projectId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SystemDtoIEnumerableApiResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV2SystemGetAllProjectIdGet(projectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV2SystemIdDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV2SystemIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV2SystemIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SystemInfoDtoApiResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV2SystemIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {boolean} [isDuplicate] 
         * @param {SystemInfoDto} [systemInfoDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV2SystemPost(isDuplicate?: boolean, systemInfoDto?: SystemInfoDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Int32ApiResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV2SystemPost(isDuplicate, systemInfoDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [projectUnit] 
         * @param {SystemInfoDto} [systemInfoDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV2SystemPut(projectUnit?: number, systemInfoDto?: SystemInfoDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV2SystemPut(projectUnit, systemInfoDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [projectId] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [search] 
         * @param {string} [sortField] 
         * @param {boolean} [isDescending] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV2SystemSearchSystemsByPagingGet(projectId?: number, page?: number, pageSize?: number, search?: string, sortField?: string, isDescending?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SystemDtoIEnumerableApiResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV2SystemSearchSystemsByPagingGet(projectId, page, pageSize, search, sortField, isDescending, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SmartComponentDesignationDto} [smartComponentDesignationDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV2SystemSmartComponentDesignationPost(smartComponentDesignationDto?: SmartComponentDesignationDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LegDtoIEnumerableApiResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV2SystemSmartComponentDesignationPost(smartComponentDesignationDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV2SystemUniqueStringGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StringApiResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV2SystemUniqueStringGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SystemApi - factory interface
 * @export
 */
export const SystemApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SystemApiFp(configuration)
    return {
        /**
         * 
         * @param {number} [projectUnit] 
         * @param {SystemInfoDto} [systemInfoDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV2SystemCalculationForAllPost(projectUnit?: number, systemInfoDto?: SystemInfoDto, options?: any): AxiosPromise<MaxSpanForAllLegsApiResponse> {
            return localVarFp.apiV2SystemCalculationForAllPost(projectUnit, systemInfoDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [projectUnit] 
         * @param {CalculationForIntelDto} [calculationForIntelDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV2SystemCalculationForIntelPost(projectUnit?: number, calculationForIntelDto?: CalculationForIntelDto, options?: any): AxiosPromise<MaxSpanForLegApiResponse> {
            return localVarFp.apiV2SystemCalculationForIntelPost(projectUnit, calculationForIntelDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SystemInfoDto} [systemInfoDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV2SystemCalculationPost(systemInfoDto?: SystemInfoDto, options?: any): AxiosPromise<CalculationResultForUserPostureApiResponse> {
            return localVarFp.apiV2SystemCalculationPost(systemInfoDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV2SystemGetAllProjectIdGet(projectId: number, options?: any): AxiosPromise<SystemDtoIEnumerableApiResponse> {
            return localVarFp.apiV2SystemGetAllProjectIdGet(projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV2SystemIdDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiV2SystemIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV2SystemIdGet(id: number, options?: any): AxiosPromise<SystemInfoDtoApiResponse> {
            return localVarFp.apiV2SystemIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {boolean} [isDuplicate] 
         * @param {SystemInfoDto} [systemInfoDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV2SystemPost(isDuplicate?: boolean, systemInfoDto?: SystemInfoDto, options?: any): AxiosPromise<Int32ApiResponse> {
            return localVarFp.apiV2SystemPost(isDuplicate, systemInfoDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [projectUnit] 
         * @param {SystemInfoDto} [systemInfoDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV2SystemPut(projectUnit?: number, systemInfoDto?: SystemInfoDto, options?: any): AxiosPromise<void> {
            return localVarFp.apiV2SystemPut(projectUnit, systemInfoDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [projectId] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [search] 
         * @param {string} [sortField] 
         * @param {boolean} [isDescending] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV2SystemSearchSystemsByPagingGet(projectId?: number, page?: number, pageSize?: number, search?: string, sortField?: string, isDescending?: boolean, options?: any): AxiosPromise<SystemDtoIEnumerableApiResponse> {
            return localVarFp.apiV2SystemSearchSystemsByPagingGet(projectId, page, pageSize, search, sortField, isDescending, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SmartComponentDesignationDto} [smartComponentDesignationDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV2SystemSmartComponentDesignationPost(smartComponentDesignationDto?: SmartComponentDesignationDto, options?: any): AxiosPromise<LegDtoIEnumerableApiResponse> {
            return localVarFp.apiV2SystemSmartComponentDesignationPost(smartComponentDesignationDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV2SystemUniqueStringGet(options?: any): AxiosPromise<StringApiResponse> {
            return localVarFp.apiV2SystemUniqueStringGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SystemApi - object-oriented interface
 * @export
 * @class SystemApi
 * @extends {BaseAPI}
 */
export class SystemApi extends BaseAPI {
    /**
     * 
     * @param {number} [projectUnit] 
     * @param {SystemInfoDto} [systemInfoDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public apiV2SystemCalculationForAllPost(projectUnit?: number, systemInfoDto?: SystemInfoDto, options?: AxiosRequestConfig) {
        return SystemApiFp(this.configuration).apiV2SystemCalculationForAllPost(projectUnit, systemInfoDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [projectUnit] 
     * @param {CalculationForIntelDto} [calculationForIntelDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public apiV2SystemCalculationForIntelPost(projectUnit?: number, calculationForIntelDto?: CalculationForIntelDto, options?: AxiosRequestConfig) {
        return SystemApiFp(this.configuration).apiV2SystemCalculationForIntelPost(projectUnit, calculationForIntelDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SystemInfoDto} [systemInfoDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public apiV2SystemCalculationPost(systemInfoDto?: SystemInfoDto, options?: AxiosRequestConfig) {
        return SystemApiFp(this.configuration).apiV2SystemCalculationPost(systemInfoDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} projectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public apiV2SystemGetAllProjectIdGet(projectId: number, options?: AxiosRequestConfig) {
        return SystemApiFp(this.configuration).apiV2SystemGetAllProjectIdGet(projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public apiV2SystemIdDelete(id: number, options?: AxiosRequestConfig) {
        return SystemApiFp(this.configuration).apiV2SystemIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public apiV2SystemIdGet(id: number, options?: AxiosRequestConfig) {
        return SystemApiFp(this.configuration).apiV2SystemIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {boolean} [isDuplicate] 
     * @param {SystemInfoDto} [systemInfoDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public apiV2SystemPost(isDuplicate?: boolean, systemInfoDto?: SystemInfoDto, options?: AxiosRequestConfig) {
        return SystemApiFp(this.configuration).apiV2SystemPost(isDuplicate, systemInfoDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [projectUnit] 
     * @param {SystemInfoDto} [systemInfoDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public apiV2SystemPut(projectUnit?: number, systemInfoDto?: SystemInfoDto, options?: AxiosRequestConfig) {
        return SystemApiFp(this.configuration).apiV2SystemPut(projectUnit, systemInfoDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [projectId] 
     * @param {number} [page] 
     * @param {number} [pageSize] 
     * @param {string} [search] 
     * @param {string} [sortField] 
     * @param {boolean} [isDescending] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public apiV2SystemSearchSystemsByPagingGet(projectId?: number, page?: number, pageSize?: number, search?: string, sortField?: string, isDescending?: boolean, options?: AxiosRequestConfig) {
        return SystemApiFp(this.configuration).apiV2SystemSearchSystemsByPagingGet(projectId, page, pageSize, search, sortField, isDescending, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SmartComponentDesignationDto} [smartComponentDesignationDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public apiV2SystemSmartComponentDesignationPost(smartComponentDesignationDto?: SmartComponentDesignationDto, options?: AxiosRequestConfig) {
        return SystemApiFp(this.configuration).apiV2SystemSmartComponentDesignationPost(smartComponentDesignationDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public apiV2SystemUniqueStringGet(options?: AxiosRequestConfig) {
        return SystemApiFp(this.configuration).apiV2SystemUniqueStringGet(options).then((request) => request(this.axios, this.basePath));
    }
}
