import React, { useState } from 'react'
import { Document, Page } from 'react-pdf'
import { Box, Button, Modal } from '@mui/material'
import { GlobalWorkerOptions } from 'pdfjs-dist'
import ModalContentPdf from '../../common/components/ModalPdf'

interface ViewPdfProp {
  closePdf: () => void
  openPdf: () => void
}

// Set the workerSrc path for pdfjs-dist
GlobalWorkerOptions.workerSrc = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.10.377/pdf.worker.min.js'

const TermsOfLicense = ({ closePdf, openPdf }: ViewPdfProp): JSX.Element => {
  const [numPages, setNumPages] = useState(null)
  const [pageNumber, setPageNumber] = useState(1)
  const [scale] = useState(1.1)

  const onDocumentLoadSuccess = ({ numPages }: any): void => {
    setNumPages(numPages)
  }

  return (
    <Modal
      open={openPdf as any}
      onClose={closePdf}
    >
      <Box style={{ height: '100vh' }}>
        <ModalContentPdf title={'Terms of license'} onClose={closePdf} width={860}>
          <Box sx={{ overflowY: 'auto', height: '100%', display: 'flex', justifyContent: 'center' }}>
            <Document
              file="/TermsOfLicense.pdf"
              onLoadSuccess={onDocumentLoadSuccess}
            >
              <Page pageNumber={pageNumber} scale={scale} />
            </Document>
          </Box>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
            <Button
              disabled={pageNumber <= 1}
              onClick={() => { setPageNumber(pageNumber - 1) }}
            >
              Previous
            </Button>
            <span>
              {pageNumber} of {numPages}
            </span>
            <Button
              disabled={pageNumber >= Number(numPages)}
              onClick={() => { setPageNumber(pageNumber + 1) }}
            >
              Next
            </Button>
          </div>
        </ModalContentPdf>
      </Box>
    </Modal>
  )
}

export default TermsOfLicense
