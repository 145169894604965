import { Editor, Frame, Element } from '@craftjs/core'
import React, { useRef, useState } from 'react'

import { Container, Text } from './components/selectors'
import { Image } from './components/selectors/Image'
import { Box, Button, Typography } from '@mui/material'
import { ButtonCustoms } from './components/selectors/Button'
import { useTranslation } from 'react-i18next'
import { ReportTemplateModal } from './components'
import IconComponent from './components/selectors/Icon/IconCheck'
import { RenderNode, Viewport } from './components/editor'
import KeyTranslation from '../../common/KeyTranslation.json'
import IconError from './components/selectors/IconError/IconError'
import IconWaring from './components/selectors/IconWarning/IconWaring'
export interface AppEditorProps {
  dataTagResult: any
  templateJson: any
  templateHtml: any
  curentPage: number
}
export interface PageProps {
  newJson: any
  newHtml: any
}

function App (): JSX.Element {
  const { t } = useTranslation()
  const ref = useRef<any>(null)
  const [visible, setVisible] = useState(true)
  const [editorData, setEditorData] = useState<AppEditorProps>()
  const generateHtml = (): string => {
    const html: string = ref.current?.firstChild?.outerHTML ?? ''
    const htmlWithPageBreakHtml = `${html} <div style='page-break-before: always'></div>`
    return htmlWithPageBreakHtml
  }
  return (
    <>
      <Box className="h-full relative">
        <Box
          className={'headerTemplate'}
        >
          <Typography variant="h1" style={{ margin: 4 }}>
            {t(KeyTranslation.Report_Template)}
          </Typography>
          <Button
            variant="contained"
            className='mb20'
            onClick={() => {
              setVisible(true)
            }}
          >
            {t(KeyTranslation.Select_Template)}
          </Button>
        </Box>
        <Editor
          resolver={{
            Container,
            Text,
            ButtonCustoms,
            Image,
            IconComponent,
            IconError,
            IconWaring
          }}
          enabled={false}
          onRender={RenderNode}
        >
          <ReportTemplateModal open={visible} onClose={(editorProps) => {
            if (editorProps != null) setEditorData(editorProps)
            setVisible(false)
          }} />
          {editorData !== undefined &&
           <Viewport editorData={editorData} currentRef={ref} generateHtml={generateHtml} onChangePage={(newPage: number, page: PageProps): any => {
             setEditorData({
               ...editorData,
               curentPage: newPage,
               templateJson: page.newJson,
               templateHtml: page.newHtml
             })
           }} >
            <div ref={ref}>
              <Frame data={(editorData?.templateJson)[editorData.curentPage]}>
                <Element canvas is={Container}>
                </Element>
              </Frame>
              </div>
          </Viewport>
          }
        </Editor>
      </Box>
    </>
  )
}
export default App
