/* tslint:disable */
/* eslint-disable */
/**
 * ESDS.Web.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { UserProfileModelApiResponse } from '../models';
/**
 * OAuthApi - axios parameter creator
 * @export
 */
export const OAuthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} userName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV2OAuthForgotPasswordUserNamePost: async (userName: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userName' is not null or undefined
            assertParamExists('apiV2OAuthForgotPasswordUserNamePost', 'userName', userName)
            const localVarPath = `/api/v2/OAuth/ForgotPassword/{userName}`
                .replace(`{${"userName"}}`, encodeURIComponent(String(userName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} securityCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV2OAuthGetUserInfoBySecurityCodeSecurityCodePost: async (securityCode: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'securityCode' is not null or undefined
            assertParamExists('apiV2OAuthGetUserInfoBySecurityCodeSecurityCodePost', 'securityCode', securityCode)
            const localVarPath = `/api/v2/OAuth/GetUserInfoBySecurityCode/{securityCode}`
                .replace(`{${"securityCode"}}`, encodeURIComponent(String(securityCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV2OAuthLogoutGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/OAuth/Logout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OAuthApi - functional programming interface
 * @export
 */
export const OAuthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OAuthApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} userName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV2OAuthForgotPasswordUserNamePost(userName: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV2OAuthForgotPasswordUserNamePost(userName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} securityCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV2OAuthGetUserInfoBySecurityCodeSecurityCodePost(securityCode: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserProfileModelApiResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV2OAuthGetUserInfoBySecurityCodeSecurityCodePost(securityCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV2OAuthLogoutGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV2OAuthLogoutGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OAuthApi - factory interface
 * @export
 */
export const OAuthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OAuthApiFp(configuration)
    return {
        /**
         * 
         * @param {string} userName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV2OAuthForgotPasswordUserNamePost(userName: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV2OAuthForgotPasswordUserNamePost(userName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} securityCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV2OAuthGetUserInfoBySecurityCodeSecurityCodePost(securityCode: string, options?: any): AxiosPromise<UserProfileModelApiResponse> {
            return localVarFp.apiV2OAuthGetUserInfoBySecurityCodeSecurityCodePost(securityCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV2OAuthLogoutGet(options?: any): AxiosPromise<void> {
            return localVarFp.apiV2OAuthLogoutGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OAuthApi - object-oriented interface
 * @export
 * @class OAuthApi
 * @extends {BaseAPI}
 */
export class OAuthApi extends BaseAPI {
    /**
     * 
     * @param {string} userName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OAuthApi
     */
    public apiV2OAuthForgotPasswordUserNamePost(userName: string, options?: AxiosRequestConfig) {
        return OAuthApiFp(this.configuration).apiV2OAuthForgotPasswordUserNamePost(userName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} securityCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OAuthApi
     */
    public apiV2OAuthGetUserInfoBySecurityCodeSecurityCodePost(securityCode: string, options?: AxiosRequestConfig) {
        return OAuthApiFp(this.configuration).apiV2OAuthGetUserInfoBySecurityCodeSecurityCodePost(securityCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OAuthApi
     */
    public apiV2OAuthLogoutGet(options?: AxiosRequestConfig) {
        return OAuthApiFp(this.configuration).apiV2OAuthLogoutGet(options).then((request) => request(this.axios, this.basePath));
    }
}
