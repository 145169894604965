import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { OAuthApi } from '../../common/services'
import { Box, Typography } from '@mui/material'

const Logout = (): JSX.Element => {
  const navigate = useNavigate()

  useEffect(() => {
    const logoutUser = async (): Promise<void> => {
      try {
        await new OAuthApi().apiV2OAuthLogoutGet()
        localStorage.clear()
        window.location.href = process.env.REACT_APP_LOGIN_URL ?? ''
      } catch (error) {
        console.log(error)
      }
    }

    void logoutUser()
  }, [navigate])

  return <Box display="flex" flexDirection="column" alignItems="center">
        <img src={require('../../assets/images/3M_Active Safety_RGB_H43.png')} alt='logo' height={36} />
        <Typography variant='h3' mt={4}>Logging out...</Typography>
      </Box>
}

export default Logout
